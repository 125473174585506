import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const menuItems = [
  { name: '유저 정보', path: '/users', activePaths: ['/users', '/userdetail', '/userreport', '/usercounsel', '/userexerciserate', '/useranalyze', '/userpost', '/usercomment', '/userpremium'] },
  { name: '전체 상담 기록', path: '/counsel', activePaths: ['/counsel'] },
  { name: '운동 정보 DB', path: '/exercise', activePaths: ['/exercise', '/exercisedetail'] },
  { name: '백업 운동 정보 DB', path: '/backup/exercise', activePaths: ['/backup/exercise', '/backup/exercisedetail'] },
  { name: '설문 DB', path: '/survey', activePaths: ['/survey', '/surveydetail'] },
  { name: '백업 설문 DB', path: '/backup/survey', activePaths: ['/backup/surveydetail', '/backup/survey'] },
  { name: '커뮤니티', path: '/community', activePaths: ['/community'] },
  { name: '삭제된 커뮤니티', path: '/communityremoved', activePaths: ['/communityremoved'] },
  { name: '결제 페이지 설정', path: '/checkoutoptions', activePaths: ['/checkoutoptions'] },
  { name: '약관', path: '/terms', activePaths: ['/terms'] },
  { name: '공지사항 / 이벤트', path: '/notice', activePaths: ['/notice'] },
];

const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="w-64 h-full bg-primary text-white p-6">
      <h2 className="text-xl font-bold mb-6 cursor-pointer" onClick={() => navigate('/')}>Bebetherafit Admin</h2>
      <ul className="space-y-4">
        {menuItems.map((item) => {
          const isActive = item.activePaths.includes(location.pathname);
          return (
            <li
              key={item.name}
              className={`p-2 rounded cursor-pointer ${
                isActive ? 'bg-green-700' : 'hover:bg-green-600'
              }`}
              onClick={() => navigate(item.path)}
            >
              {item.name}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SideBar;