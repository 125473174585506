import React, { useState, useEffect } from 'react';
import { auth } from '../firebaseConfig'; // Firebase Auth 가져오기
import { signInWithEmailAndPassword } from 'firebase/auth';
import logo from '../assets/imgs/bebelogo.png';

const Main = ({ setAuthenticated }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const sessionAuth = sessionStorage.getItem('authenticated');
    if (sessionAuth) {
      setIsLoggedIn(true);
      setAuthenticated(true);
    }
  }, [setAuthenticated]);

  const handleLogin = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      
      // 토큰 가져오기
      const token = await user.getIdToken();
      
      // 토큰을 세션 스토리지에 저장
      sessionStorage.setItem('token', token);
  
      setAuthenticated(true);
      setIsLoggedIn(true);
      sessionStorage.setItem('authenticated', 'true');
    } catch (error) {
      setError('Incorrect email or password');
      setPassword('');
    }
  };  

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <div className="flex items-center justify-center h-[90vh] bg-gray-100">
      {isLoggedIn ? (
        <div className="flex flex-col items-center text-center space-y-4">
          <img src={logo} alt="Admin Logo" className="w-60 h-auto mb-4" />
          <div className="text-gray-700 text-2xl font-bold">Welcome Admin!</div>
        </div>
      ) : (
        <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md text-center space-y-4">
          <h2 className="text-gray-700 text-2xl font-semibold">Welcome Admin Page</h2>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email"
            className="border border-gray-300 p-3 rounded w-full focus:outline-none focus:ring focus:ring-blue-200"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyPress}
            placeholder="Enter password"
            className="border border-gray-300 p-3 rounded w-full focus:outline-none focus:ring focus:ring-blue-200 mt-4"
          />
          <button
            onClick={handleLogin}
            className="bg-blue-500 text-white px-4 py-2 rounded w-full hover:bg-blue-600 transition-colors duration-200"
          >
            Login
          </button>
          {error && <p className="text-red-500">{error}</p>}
        </div>
      )}
    </div>
  );
};

export default Main;