import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig.js';
import LoadingSpinner from '../components/LoadingSpinner.jsx';

const UserCounselDetail = () => {
  const location = useLocation();
  const { allDetails, year, docId, userId } = location.state || {};
  const [context, setContext] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [localDetails, setLocalDetails] = useState(allDetails); // 서버 데이터를 로컬 상태로 저장
  const scrollRef = useRef(null);

  if (!allDetails) {
    return <div>No details found</div>;
  }

  // 가장 오래된 순서로 필드 정렬
  const sortedFields = Object.keys(localDetails).sort();

  // 스크롤을 최하단으로 이동
  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Firebase에 새 답글을 추가하는 함수
  const handleAddReply = async () => {
    if (!context.trim()) return;

    setIsLoading(true); // 로딩 시작

    // 한국 시간 기준으로 yyyyMMddhhmmss 형식의 타임스탬프 생성
    const now = new Date();
    const koreaTimeOffset = 9 * 60 * 60 * 1000;
    const koreaTime = new Date(now.getTime() + koreaTimeOffset);
    const timestamp = koreaTime.toISOString().replace(/[-:.TZ]/g, "").slice(0, 14); // 예: 20241104171550

    const newEntry = {
      details: {
        context,
        images: [], // 필요한 경우 이미지를 추가
      },
      user: 'admin',
    };

    try {
      const docRef = doc(db, `counselHistory/${userId}/${year}/${docId}`);
      await setDoc(docRef, { [timestamp]: newEntry }, { merge: true });
      console.log("Reply added successfully!");

      // 로컬 상태에 새 답글 추가
      setLocalDetails((prevDetails) => ({
        ...prevDetails,
        [timestamp]: newEntry,
      }));

      // 입력 필드를 초기화하고 알림 표시
      setContext("");
      alert("답글이 성공적으로 추가되었습니다.");
    } catch (error) {
      console.error("Error adding reply:", error);
      alert("답글 추가 중 오류가 발생했습니다.");
    } finally {
      setIsLoading(false); // 로딩 종료
    }
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen relative">
      {isLoading && ( <LoadingSpinner /> )}

      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-700">상담 내용 - {year}.{docId}</h1>
        <button
          onClick={scrollToBottom}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600"
        >
          답글 작성
        </button>
      </div>
      
      <div className="space-y-4">
        {sortedFields.map((field, index) => {
          const entry = localDetails[field];
          const isCurrentUser = entry.user === userId;

          return (
            <div key={index} className="p-4 bg-white border rounded-lg shadow-md">
              <h3 className="text-lg font-semibold text-gray-800 mb-2">Date: {field}</h3>
              <p className="text-gray-700 mb-2">
                <strong>User ID:</strong> {entry.user}
                <span className={isCurrentUser ? "text-green-500" : "text-red-500"}>
                  {isCurrentUser ? " (User)" : " (Admin)"}
                </span>
              </p>
              <p className="text-gray-700 mb-2"><strong>Context:</strong> {entry.details.context}</p>
              <div className="flex flex-wrap gap-2 mt-2">
                {entry.details.images && entry.details.images.map((image, imgIndex) => (
                  <img key={imgIndex} src={image} alt="CounselI" className="w-32 object-contain rounded-lg" />
                ))}
              </div>
            </div>
          );
        })}
      </div>

      {/* 답글 작성 인풋창 */}
      <div ref={scrollRef} className="mt-6 p-4 bg-white border rounded-lg shadow-md">
        <h2 className="text-lg font-semibold text-gray-800 mb-2">답글 작성</h2>
        <textarea
          value={context}
          onChange={(e) => setContext(e.target.value)}
          placeholder="답글 내용을 입력하세요..."
          className="w-full p-2 border rounded-lg mb-4"
        ></textarea>
        <button
          onClick={handleAddReply}
          className="px-4 py-2 bg-green-500 text-white rounded-lg shadow hover:bg-green-600"
          disabled={isLoading}
        >
          답글 제출
        </button>
      </div>
    </div>
  );
};

export default UserCounselDetail;