import React, { createContext, useState, useEffect } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../firebaseConfig';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [allUsers, setAllUsers] = useState([]);
  const [isUserLoading, setIsUserLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = () => {
      const usersRef = collection(db, 'users');

      const unsubscribe = onSnapshot(usersRef, (snapshot) => {
        const updatedUsers = snapshot.docs.map((doc) => ({
          uid: doc.id,
          name: doc.data().name || 'No Name',
          picture: doc.data().picture || '',
        }));
        setAllUsers(updatedUsers);
        setIsUserLoading(false);
      }, (error) => {
        console.error("Error fetching users:", error);
        setIsUserLoading(false);
      });

      return () => unsubscribe(); // 컴포넌트가 언마운트 될 때 실시간 구독 해제
    };

    fetchUsers();
  }, []);

  return (
    <UserContext.Provider value={{ allUsers, isUserLoading }}>
      {children}
    </UserContext.Provider>
  );
};