import React, { useEffect, useState } from 'react';
import { db } from '../firebaseConfig.js';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import LoadingSpinner from '../components/LoadingSpinner.jsx';

const CheckOutOptions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchCheckoutData = async () => {
      setIsLoading(true);
      try {
        const optionsDocRef = doc(db, 'checkoutpage', 'options');
        const optionsDoc = await getDoc(optionsDocRef);

        if (optionsDoc.exists()) {
          setOptions(optionsDoc.data().data || []);
        } else {
          setOptions([]);
        }
      } catch (error) {
        console.error('Error fetching checkout data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCheckoutData();
  }, []);

  const handleInputChange = (index, field, value) => {
    const updatedOptions = [...options];
    updatedOptions[index][field] = value;
    setOptions(updatedOptions);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const cleanedOptions = options.map((option) => {
        // 빈값은 제거
        return Object.fromEntries(
          Object.entries(option).filter(([_, value]) => value.trim() !== '')
        );
      });

      await setDoc(doc(db, 'checkoutpage', 'options'), { data: cleanedOptions });
      alert('Options successfully updated!');
    } catch (error) {
      console.error('Error submitting options:', error);
      alert('Failed to update options. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen relative">
      {isLoading && <LoadingSpinner />}

      {!isLoading && (
        <div>
          <h1 className="text-2xl font-bold mb-4">Checkout Options</h1>

          {options.map((option, index) => (
            <div key={index} className="bg-white p-4 rounded shadow mb-4 relative">

              <div className="mb-4">
                <p className="block mb-2 font-semibold">Label:</p>
                <div className="w-full border p-2 rounded bg-gray-200">{option.label}</div>
              </div>

              <div className="mb-4">
                <p className="block mb-2 font-semibold">Details:</p>
                <div className="w-full border p-2 rounded bg-gray-200">{option.details}</div>
              </div>

              <label className="block mb-2 font-semibold">Original Price:</label>
              <input
                type="text"
                value={option.originalPrice}
                onChange={(e) => handleInputChange(index, 'originalPrice', e.target.value)}
                className="w-full border p-2 rounded mb-4"
                placeholder="상품의 원 가격을 입력하세요. 실제 결제 가격과 같은 경우에는 빈 값으로 두세요."
              />

              <label className="block mb-2 font-semibold">Price:</label>
              <input
                type="text"
                value={option.price}
                onChange={(e) => handleInputChange(index, 'price', e.target.value)}
                className="w-full border p-2 rounded mb-4"
                placeholder="실제 결제 가격을 입력하세요."
              />

              <label className="block mb-2 font-semibold">Discount:</label>
              <input
                type="text"
                value={option.discount}
                onChange={(e) => handleInputChange(index, 'discount', e.target.value)}
                className="w-full border p-2 rounded mb-4"
                placeholder="할인률을 입력하세요. (예: 10%할인)"
              />
            </div>
          ))}

          <button
            onClick={handleSubmit}
            disabled={isLoading}
            className={`px-4 py-2 rounded ${
              isLoading ? 'bg-gray-400' : 'bg-green-500 text-white'
            }`}
          >
            {isLoading ? '저장중...' : '저장'}
          </button>
        </div>
      )}
    </div>
  );
};

export default CheckOutOptions;
