import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { collection, getDocs, doc, updateDoc, setDoc, deleteDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db } from '../firebaseConfig.js';
import LoadingSpinner from '../components/LoadingSpinner.jsx';

const BackupSurveyDetail = () => {
  const location = useLocation();
  const { doc: docId, subCollection } = location.state || {};
  const [questions, setQuestions] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const bottomRef = useRef(null);

  useEffect(() => {
    fetchQuestions();
    // eslint-disable-next-line 
  }, [docId, subCollection]);

  const fetchQuestions = async () => {
    if (!docId || !subCollection) return;

    setIsLoading(true);
    try {
      const questionsRef = collection(db, `backup_questions/${docId}/${subCollection}`);
      const snapshot = await getDocs(questionsRef);
      const fetchedQuestions = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      fetchedQuestions.sort((a, b) => Number(a.id) - Number(b.id));
      setQuestions(fetchedQuestions);
    } catch (error) {
      console.error("Error fetching questions:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const addNewSurvey = async () => {
    const confirmAdd = window.confirm("설문을 추가하시겠습니까?");
    if (!confirmAdd) return;

    setIsLoading(true);
    try {
      const newId = (Math.max(...questions.map(q => Number(q.id))) + 1).toString();
      const newQuestion = {
        header: '',
        title: '',
        detail: '',
        answers: [{ answer: '', score: 0 }],
        image_url: ''
      };

      await setDoc(doc(db, `backup_questions/${docId}/${subCollection}`, newId), newQuestion);
      setQuestions(prev => [...prev, { id: newId, ...newQuestion }]);

      setTimeout(() => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    } catch (error) {
      console.error("Error adding new survey:", error);
      alert("서버 통신 중 오류가 발생했습니다.");
    } finally {
      fetchQuestions();
      setIsLoading(false);
    }
  };

  const deleteSurvey = async (questionId) => {
    const confirmDelete = window.confirm("해당 설문을 삭제하시겠습니까?");
    if (!confirmDelete) return;

    setIsLoading(true);
    try {
      await deleteDoc(doc(db, `backup_questions/${docId}/${subCollection}`, questionId));
      setQuestions(prevQuestions => prevQuestions.filter(q => q.id !== questionId));
      alert("해당 설문을 삭제했습니다.");
    } catch (error) {
      console.error("Error deleting survey:", error);
      alert("서버 통신 중 오류가 발생했습니다.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (questionId, field, value) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((q) =>
        q.id === questionId ? { ...q, [field]: value } : q
      )
    );
  };

  const handleAnswerChange = (questionId, answerIndex, field, value) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((q) =>
        q.id === questionId
          ? {
              ...q,
              answers: q.answers.map((a, i) =>
                i === answerIndex ? { ...a, [field]: value } : a
              ),
            }
          : q
      )
    );
  };

  const addAnswer = (questionId) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((q) =>
        q.id === questionId
          ? {
              ...q,
              answers: [...q.answers, { answer: '', score: 0 }],
            }
          : q
      )
    );
  };

  const removeAnswer = (questionId, answerIndex) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((q) =>
        q.id === questionId
          ? {
              ...q,
              answers: q.answers.filter((_, i) => i !== answerIndex),
            }
          : q
      )
    );
  };

  const handleImageUpload = async (e, questionId) => {
    const file = e.target.files[0];
    if (!file) return;

    setIsLoading(true);
    const storage = getStorage();
    const storageRef = ref(storage, `media/survey/${file.name}`);
    try {
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);

      setQuestions((prevQuestions) =>
        prevQuestions.map((q) =>
          q.id === questionId ? { ...q, image_url: downloadURL } : q
        )
      );
      alert("스토리지에 이미지 업로드 완료.");
    } catch (error) {
      console.error("Error uploading image:", error);
      alert("이미지 업로드 실패.");
    } finally {
      setIsLoading(false);
    }
  };

  const saveQuestion = async (question) => {
    const confirmSave = window.confirm("해당 백업 설문을 저장하시겠습니까?");
    if (!confirmSave) return;

    setIsSaving(true);
    try {
      const { id, ...questionData } = question;
      const questionRef = doc(db, `backup_questions/${docId}/${subCollection}`, id);
      await updateDoc(questionRef, questionData);
      alert("백업 설문 저장 성공.");
    } catch (error) {
      console.error("Error saving question:", error);
      alert("백업 설문 저장 실패.");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-md">
      {isLoading && <LoadingSpinner />}
      
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold text-gray-700">
          [백업] 설문 내용 / {docId} - {subCollection}
        </h1>
        <div className="flex space-x-2">
          <button
            onClick={addNewSurvey}
            className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors duration-200"
          >
            설문 추가
          </button>
        </div>
      </div>

      <div className="space-y-4">
        {questions.length > 0 ? (
          questions.map((question) => (
            <div key={question.id} className="p-4 bg-white border rounded-lg shadow-md relative">
              <button
                onClick={() => deleteSurvey(question.id)}
                className="absolute top-2 right-2 px-2 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors duration-200"
              >
                삭제
              </button>
              <label className="block text-gray-700 mb-2"><strong>질문 ID:</strong> {question.id}</label>

              <label className="block text-gray-800 font-semibold">헤더:</label>
              <input
                type="text"
                value={question.header}
                onChange={(e) => handleInputChange(question.id, 'header', e.target.value)}
                className="w-full p-2 border rounded mb-2"
                placeholder="헤더 입력"
              />

              <label className="block text-gray-800 font-semibold">제목:</label>
              <input
                type="text"
                value={question.title}
                onChange={(e) => handleInputChange(question.id, 'title', e.target.value)}
                className="w-full p-2 border rounded mb-2"
              />

              <label className="block text-gray-600 font-semibold">상세내용:</label>
              <textarea
                value={question.detail}
                onChange={(e) => handleInputChange(question.id, 'detail', e.target.value)}
                className="w-full p-2 border rounded mb-2"
              />

              <div>
                <label className="block text-gray-700 font-semibold">이미지 URL:</label>
                <div className="flex items-center space-x-2">
                  <input
                    type="text"
                    value={question.image_url || ''}
                    onChange={(e) => handleInputChange(question.id, 'image_url', e.target.value)}
                    className="w-full p-2 border rounded mb-2"
                    placeholder="이미지 URL 입력"
                  />
                  <label className="w-[160px] py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 cursor-pointer flex justify-center items-center">
                    Image Upload
                    <input
                      type="file"
                      onChange={(e) => handleImageUpload(e, question.id)}
                      className="hidden"
                    />
                  </label>
                </div>
                {question.image_url && (
                  <img src={question.image_url} alt="Question Visual" className="w-42 object-contain rounded mb-2" />
                )}
              </div>

              <div className="mt-4">
                <h4 className="font-semibold text-gray-700 mb-2">선택지:</h4>
                <ul className="space-y-3">
                  {question.answers.map((answer, index) => (
                    <li key={index} className="flex items-center space-x-4 bg-gray-50 p-3 rounded-md shadow-sm">
                      <div className="flex-grow">
                        <label className="block font-semibold text-gray-600">답변:</label>
                        <input
                          type="text"
                          value={answer.answer}
                          onChange={(e) => handleAnswerChange(question.id, index, 'answer', e.target.value)}
                          className="w-full p-2 border rounded mb-2"
                          placeholder="답변 내용"
                        />

                        <label className="block font-semibold text-gray-600">점수:</label>
                        <input
                          type="number"
                          value={answer.score}
                          onChange={(e) => handleAnswerChange(question.id, index, 'score', Number(e.target.value))}
                          className="w-full p-2 border rounded"
                          placeholder="점수"
                        />
                      </div>
                      <button
                        onClick={() => removeAnswer(question.id, index)}
                        className="px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors duration-200"
                      >
                        삭제
                      </button>
                    </li>
                  ))}
                </ul>
                <button
                  onClick={() => addAnswer(question.id)}
                  className="mt-3 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors duration-200"
                >
                  선택지 추가
                </button>
              </div>

              <button
                onClick={() => saveQuestion(question)}
                disabled={isSaving}
                className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-200 disabled:bg-gray-300"
              >
                {isSaving ? '저장 중...' : '저장'}
              </button>
              <div ref={bottomRef}></div>
            </div>
          ))
        ) : (
          <p className="text-gray-700">No questions found.</p>
        )}
      </div>
    </div>
  );
};

export default BackupSurveyDetail;