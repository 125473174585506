import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

const E3s = ['바른성장', '발달', '신체밸런스', '신체활동', '체형개선'];
const exerciseGroups = ['미션', '추천', ...E3s, '맞춤'];
const subCollections = Array.from({ length: 13 }, (_, i) => i.toString());

const Exercise = () => {
  const db = getFirestore();
  const navigate = useNavigate();

  const handleFetchData = async (group, subCollection) => {
    try {
      const colRef = collection(db, `exercise/${group}/${subCollection}`);
      const snapshot = await getDocs(colRef);

      const data = [];
      snapshot.forEach(doc => {
        data.push({ id: doc.id, ...doc.data() });
      });

      navigate('/exercisedetail', { state: { group, subCollection, data } });
    } catch (error) {
      console.error("데이터를 가져오는 중 오류가 발생했습니다:", error);
    }
  };

  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-4 text-gray-700">운동 카테고리 (종류, 나이)</h1>
      
      {exerciseGroups.map((group, groupIndex) => (
        <div key={groupIndex} className="mb-6">
          <h2 className="text-xl font-semibold mb-2 text-gray-600">{group}</h2>
          <div className="flex gap-4">
            {subCollections.map((subCollection) => (
              <button
                key={`${group}-${subCollection}`}
                className="px-4 py-2 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600 transition-colors"
                onClick={() => handleFetchData(group, subCollection)}
              >
                {subCollection}
              </button>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Exercise;