import React, { useEffect, useState, useContext } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig.js';
import { UserContext } from '../Contexts/UserContext.jsx';
import LoadingSpinner from '../components/LoadingSpinner.jsx';

const CommunityRemoved = () => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [comments, setComments] = useState({});
  const [loadingComments, setLoadingComments] = useState({});
  const { allUsers } = useContext(UserContext);

  useEffect(() => {
    const fetchPosts = async () => {
      setIsLoading(true);
      try {
        const communityRef = collection(db, 'removed_community');
        const snapshot = await getDocs(communityRef);
        const fetchedPosts = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        fetchedPosts.sort((a, b) => b.id.localeCompare(a.id));
        setPosts(fetchedPosts);
      } catch (error) {
        console.error("Error fetching community posts:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPosts();
  }, []);

  const toggleComments = async (postId) => {
    if (comments[postId]) {
      setComments((prev) => ({ ...prev, [postId]: null }));
    } else {
      setLoadingComments((prev) => ({ ...prev, [postId]: true }));
      try {
        const commentsRef = collection(db, `community/${postId}/comments`);
        const commentsSnapshot = await getDocs(commentsRef);
        const fetchedComments = [];

        commentsSnapshot.forEach((doc) => {
          const commentId = doc.id;
          const commentData = doc.data()[commentId];
          if (commentData) {
            fetchedComments.push({
              id: commentId,
              postId,
              details: commentData.details,
              uid: commentData.uid,
              updatedAt: commentData.updatedAt,
            });
          }
        });

        fetchedComments.sort((a, b) => (b.updatedAt || '').localeCompare(a.updatedAt || ''));
        setComments((prev) => ({ ...prev, [postId]: fetchedComments }));
      } catch (error) {
        console.error("Error fetching comments:", error);
      } finally {
        setLoadingComments((prev) => ({ ...prev, [postId]: false }));
      }
    }
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen relative">
      {isLoading && ( <LoadingSpinner /> )}

      <h1 className="text-2xl font-bold mb-6 text-gray-700">삭제된 게시글</h1>
      <div className="space-y-4">
        {posts.length > 0 ? (
          posts.map((post) => {
            const user = allUsers.find((u) => u.uid === post.author);
            return (
              <div key={post.id} className="p-4 bg-white border rounded-lg shadow-md">
                <h3 className="text-xl font-semibold text-gray-800 mb-2">{post.title}</h3>
                <div className="flex">
                  <div className="flex-1">
                    <p className="text-sm text-gray-500 mb-1">Author: {post.author}</p>
                    <p className="text-sm text-gray-500 mb-1">Category: {post.category}</p>
                    <p className="text-sm text-gray-500 mb-1">Tags: {post.tags}</p>
                    <p className="text-sm text-gray-500 mb-1">Created At: {post.createdAt}</p>
                    <p className="text-sm text-gray-500 mb-4">Updated At: {post.updatedAt}</p>
                  </div>
                  <div className="flex-1 flex flex-col items-center">
                    {user ? (
                      <>
                        <img src={user.picture} alt={`${user.name}'s profile`} className="w-20 h-20 rounded-full mb-2" />
                        <p className="text-sm text-gray-800 font-semibold">{user.name}</p>
                      </>
                    ) : (
                      <p className="text-sm text-gray-500">User not found</p>
                    )}
                  </div>
                </div>
                <p className="text-gray-700 mb-4">{post.contents.details}</p>

                <div className="flex gap-2 flex-wrap">
                  {post.contents.imgs && post.contents.imgs.map((img, index) => (
                    <img
                      key={index}
                      src={img}
                      alt={`Post ${index + 1}`}
                      className="w-32 h-32 object-cover rounded-lg"
                    />
                  ))}
                </div>

                <button
                  onClick={() => toggleComments(post.id)}
                  className="mt-4 px-4 py-2 bg-blue-400 text-white rounded-md hover:bg-blue-500"
                >
                  {comments[post.id] ? '댓글 닫기' : '댓글 보기'}
                </button>

                {loadingComments[post.id] && (
                  <div className="flex justify-center items-center mt-4">
                    <div className="w-6 h-6 border-4 border-gray-300 border-t-transparent rounded-full animate-spin"></div>
                  </div>
                )}

                {comments[post.id] && (
                  <div className="mt-4 space-y-2">
                    {comments[post.id].length > 0 ? (
                      comments[post.id].map((comment) => {
                        const commentUser = allUsers.find((u) => u.uid === comment.uid);
                        return (
                          <div key={comment.id} className="p-3 bg-gray-100 rounded-lg">
                            <p className="text-sm text-gray-700">{comment.details}</p>
                            <div className="flex items-center mt-1">
                              {commentUser && (
                                <img
                                  src={commentUser.picture}
                                  alt={`${commentUser.name}'s profile`}
                                  className="w-6 h-6 rounded-full mr-2"
                                />
                              )}
                              <p className="text-xs text-gray-500 mr-1">
                                작성자: {commentUser ? commentUser.name : comment.uid}
                              </p>
                              <p className="text-xs text-gray-500">
                                ({comment.uid})
                              </p>
                              <p className="text-xs text-gray-500 ml-4">작성 시간: {comment.updatedAt || 'N/A'}</p>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <p className="text-sm text-gray-500">댓글이 없습니다.</p>
                    )}
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <p className="text-gray-700">No community posts found.</p>
        )}
      </div>
    </div>
  );
};

export default CommunityRemoved;