import React, { useEffect, useState, useContext } from 'react';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { db, functions, httpsCallable } from '../firebaseConfig.js';
import { UserContext } from '../Contexts/UserContext.jsx';
import { useLocation } from 'react-router-dom';
import LoadingSpinner from '../components/LoadingSpinner.jsx';

const UserPost = () => {
  const location = useLocation();
  const { user } = location.state || {};
  const [userPosts, setUserPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [comments, setComments] = useState({});
  const [loadingComments, setLoadingComments] = useState({});
  const { allUsers } = useContext(UserContext);

  useEffect(() => {
    const fetchUserPosts = async () => {
      if (!user?.id) {
        console.error("User ID is missing");
        return;
      }

      setIsLoading(true);
      try {
        const postDocRef = doc(db, `community_logs/${user.id}/post/postIds`);
        const postDoc = await getDoc(postDocRef);

        if (postDoc.exists()) {
          const { postId: postIdArray } = postDoc.data();
          const fetchedPosts = [];

          for (const postId of postIdArray) {
            const communityPostRef = doc(db, 'community', postId);
            const communityPostDoc = await getDoc(communityPostRef);

            if (communityPostDoc.exists()) {
              fetchedPosts.push({
                id: communityPostDoc.id,
                ...communityPostDoc.data(),
              });
            }
          }

          fetchedPosts.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
          setUserPosts(fetchedPosts);
        } else {
          console.error("No post document found for the user");
        }
      } catch (error) {
        console.error("Error fetching user posts:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserPosts();
  }, [user]);

  const toggleComments = async (postId) => {
    if (comments[postId]) {
      setComments((prev) => ({ ...prev, [postId]: null }));
    } else {
      setLoadingComments((prev) => ({ ...prev, [postId]: true }));
      try {
        const commentsRef = collection(db, `community/${postId}/comments`);
        const commentsSnapshot = await getDocs(commentsRef);
        const fetchedComments = [];

        commentsSnapshot.forEach((doc) => {
          const commentId = doc.id;
          const commentData = doc.data()[commentId];
          if (commentData) {
            fetchedComments.push({
              id: commentId,
              postId,
              details: commentData.details,
              uid: commentData.uid,
              updatedAt: commentData.updatedAt,
            });
          }
        });

        fetchedComments.sort((a, b) => (b.updatedAt || '').localeCompare(a.updatedAt || ''));
        setComments((prev) => ({ ...prev, [postId]: fetchedComments }));
      } catch (error) {
        console.error("Error fetching comments:", error);
      } finally {
        setLoadingComments((prev) => ({ ...prev, [postId]: false }));
      }
    }
  };

  const handleDeletePost = async (post) => {
    const confirmDelete = window.confirm("정말로 이 게시글을 삭제하시겠습니까?");
    if (!confirmDelete) return;

    setIsLoading(true);
    try {
      const deletePost = httpsCallable(functions, 'deleteCommunityData');
      await deletePost({ postId: post.id, uid: post.author });
      
      setUserPosts((prevPosts) => prevPosts.filter((p) => p.id !== post.id));
      alert("게시글이 성공적으로 삭제되었습니다.");
    } catch (error) {
      alert("글 삭제 중 오류가 발생했습니다.");
      console.error(`Error deleting post with ID: ${post.id}`, error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteComment = async (postId, commentId) => {
    const confirmDelete = window.confirm("정말로 이 댓글을 삭제하시겠습니까?");
    if (!confirmDelete) return;

    setIsLoading(true);
    try {
      const commentToDelete = comments[postId]?.find((comment) => comment.id === commentId);
      if (!commentToDelete) {
        console.error(`Comment with ID: ${commentId} not found for post ID: ${postId}`);
        return;
      }
  
      const deleteComment = httpsCallable(functions, 'deleteCommentData');
      await deleteComment({ postId, commentId, uid: commentToDelete.uid });
      
      setComments((prevComments) => ({
        ...prevComments,
        [postId]: prevComments[postId].filter((comment) => comment.id !== commentId),
      }));
      alert("댓글이 성공적으로 삭제되었습니다.");
    } catch (error) {
      alert("댓글 삭제 중 오류가 발생했습니다.");
      console.error(`Error deleting comment with ID: ${commentId} for post ID: ${postId}`, error);
    } finally {
      setIsLoading(false);
    }
  };  

  return (
    <div className="p-6 bg-gray-100 min-h-screen relative">
      {isLoading && ( <LoadingSpinner /> )}

      <h1 className="text-2xl font-bold mb-6 text-gray-700">
        {user.name}의 게시글 ({userPosts.length})
      </h1>
      <div className="space-y-4">
        {userPosts.length > 0 ? (
          userPosts.map((post) => {
            const postUser = allUsers.find((u) => u.uid === post.author);
            return (
              <div key={post.id} className="p-4 bg-white border rounded-lg shadow-md relative">
                <div className="flex justify-between items-center">
                  <h3 className="text-xl font-semibold text-gray-800">{post.title}</h3>
                  <button
                    onClick={() => handleDeletePost(post)}
                    className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors duration-200"
                  >
                    삭제
                  </button>
                </div>
                <div className="flex">
                  <div className="flex-1">
                    <p className="text-sm text-gray-500 mb-1">Author: {post.author}</p>
                    <p className="text-sm text-gray-500 mb-1">Category: {post.category}</p>
                    <p className="text-sm text-gray-500 mb-1">Tags: {post.tags}</p>
                    <p className="text-sm text-gray-500 mb-1">Created At: {post.createdAt}</p>
                    <p className="text-sm text-gray-500 mb-4">Updated At: {post.updatedAt}</p>
                  </div>
                  <div className="flex-1 flex flex-col items-center">
                    {postUser ? (
                      <>
                        <img src={postUser.picture} alt={`${postUser.name}'s profile`} className="w-20 h-20 rounded-full mb-2" />
                        <p className="text-sm text-gray-800 font-semibold">{postUser.name}</p>
                      </>
                    ) : (
                      <p className="text-sm text-gray-500">User not found</p>
                    )}
                  </div>
                </div>
                <p className="text-gray-700 mb-4">{post.contents.details}</p>

                <div className="flex gap-2 flex-wrap">
                  {post.contents.imgs && post.contents.imgs.map((img, index) => (
                    <img
                      key={index}
                      src={img}
                      alt={`Post ${index + 1}`}
                      className="w-32 h-32 object-cover rounded-lg"
                    />
                  ))}
                </div>

                <button
                  onClick={() => toggleComments(post.id)}
                  className="mt-4 px-4 py-2 bg-blue-400 text-white rounded-md hover:bg-blue-500"
                >
                  {comments[post.id] ? '댓글 닫기' : '댓글 보기'}
                </button>

                {loadingComments[post.id] && (
                  <div className="flex justify-center items-center mt-4">
                    <div className="w-6 h-6 border-4 border-gray-300 border-t-transparent rounded-full animate-spin"></div>
                  </div>
                )}

                {comments[post.id] && (
                  <div className="mt-4 space-y-2">
                    {comments[post.id].length > 0 ? (
                      comments[post.id].map((comment) => {
                        const commentUser = allUsers.find((u) => u.uid === comment.uid);
                        return (
                          <div key={comment.id} className="p-3 bg-gray-100 rounded-lg">
                            <div className="flex justify-between items-center">
                              <p className="text-sm text-gray-700">{comment.details}</p>
                              <button
                                onClick={() => handleDeleteComment(post.id, comment.id)}
                                className="px-4 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors duration-200"
                              >
                                삭제
                              </button>
                            </div>
                            <div className="flex items-center mt-1">
                              {commentUser && (
                                <img
                                  src={commentUser.picture}
                                  alt={`${commentUser.name}'s profile`}
                                  className="w-6 h-6 rounded-full mr-2"
                                />
                              )}
                              <p className="text-xs text-gray-500 mr-2">
                                작성자: {commentUser ? commentUser.name : comment.uid}
                              </p>
                              <p className="text-xs text-gray-500">
                                ({comment.uid})
                              </p>
                              <p className="text-xs text-gray-500 ml-4">작성 시간: {comment.updatedAt || 'N/A'}</p>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <p className="text-sm text-gray-500">댓글이 없습니다.</p>
                    )}
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <p className="text-gray-700">No posts found for this user.</p>
        )}
      </div>
    </div>
  );
};

export default UserPost;