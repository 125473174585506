import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig.js';
import { collection, deleteDoc, doc, getDocs, setDoc } from 'firebase/firestore';
import LoadingSpinner from '../components/LoadingSpinner.jsx';

const NoticeEventAdmin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [notices, setNotices] = useState([]);
  const [expandedNotice, setExpandedNotice] = useState(null);
  const [editedNotices, setEditedNotices] = useState({});
  const [newNotice, setNewNotice] = useState({ title: '', subTitle: '', type: '공지', content: '', date: '' });

  useEffect(() => {
    const fetchNotices = async () => {
      setIsLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, 'noticeEvent'));
        const noticeData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setNotices(noticeData);
      } catch (error) {
        console.error('게시글 데이터를 불러오는 중 오류 발생:', error);
      }
      setIsLoading(false);
    };
    fetchNotices();
  }, []);

  const handleChange = (id, field, value) => {
    setNotices(prev => prev.map(item => item.id === id ? { ...item, [field]: value } : item));
    setEditedNotices(prev => ({ ...prev, [id]: true }));
  };

  const handleSave = async (id) => {
    try {
      setIsLoading(true);
      await setDoc(doc(db, 'noticeEvent', id), notices.find(item => item.id === id));
      setEditedNotices(prev => ({ ...prev, [id]: false }));
      alert('저장되었습니다!');
    } catch (error) {
      console.error('저장 중 오류 발생:', error);
    }
    setIsLoading(false);
  };

  const handleDelete = async (id) => {
    try {
      setIsLoading(true);
      const docRef = doc(db, 'noticeEvent', id);
      await deleteDoc(docRef);
  
      setNotices(prev => prev.filter(item => item.id !== id));
  
      alert('게시글이 삭제되었습니다!');
    } catch (error) {
      console.error('삭제 중 오류 발생:', error);
      alert('삭제 중 오류가 발생했습니다.');
    }
    setIsLoading(false);
  };

  const handleNewNoticeChange = (field, value) => {
    setNewNotice(prev => ({ ...prev, [field]: value }));
  };

  const handleAddNewNotice = async () => {
    try {
      setIsLoading(true);
      const currentDate = new Date();
      const formattedDate = currentDate.getFullYear().toString() +
        String(currentDate.getMonth() + 1).padStart(2, '0') +
        String(currentDate.getDate()).padStart(2, '0') +
        String(currentDate.getHours()).padStart(2, '0') +
        String(currentDate.getMinutes()).padStart(2, '0') +
        String(currentDate.getSeconds()).padStart(2, '0');
      const newDoc = { ...newNotice };
      await setDoc(doc(db, 'noticeEvent', formattedDate), newDoc);
      setNotices(prev => [...prev, { id: formattedDate, ...newDoc }]);
      setNewNotice({ title: '', subTitle: '', type: '공지', content: '', date: '' });
      alert('새 게시글이 추가되었습니다!');
    } catch (error) {
      console.error('새 게시글 추가 중 오류 발생:', error);
    }
    setIsLoading(false);
  };

  return (
    <div className="max-w-6xl mx-auto bg-white shadow-md rounded-lg p-6">
      {isLoading && <LoadingSpinner />}
      <h2 className="text-3xl font-bold text-gray-800 mb-6">공지 및 이벤트 관리</h2>
      
      {notices.map(item => (
        <div key={item.id} className="mb-4 border border-gray-300 rounded-lg p-4">
          <div className="flex justify-between items-center cursor-pointer" onClick={() => setExpandedNotice(expandedNotice === item.id ? null : item.id)}>
            <div>
              <h3 className="text-lg font-bold">{item.title}</h3>
              <p className="text-sm text-gray-500"><span className='text-green-500 text-bold'>{item.subTitle}</span> | {item.type} | {item.date}</p>
            </div>
            <span>{expandedNotice === item.id ? '▲' : '▼'}</span>
          </div>
          {expandedNotice === item.id && (
            <div className="mt-4">
              <label className="block text-xl font-bold text-gray-700 mb-2">제목</label>
              <input type="text" value={item.title} onChange={e => handleChange(item.id, 'title', e.target.value)} className="w-full p-3 border border-gray-300 rounded-lg" />
              
              <label className="block text-xl font-bold text-gray-700 mb-2">부제목</label>
              <input type="text" value={item.subTitle} onChange={e => handleChange(item.id, 'subtitle', e.target.value)} className="w-full p-3 border border-gray-300 rounded-lg" />
              
              <label className="block text-xl font-bold text-gray-700 mb-2">유형</label>
              <select value={item.type} onChange={e => handleChange(item.id, 'type', e.target.value)} className="w-full p-3 border border-gray-300 rounded-lg">
                <option value="공지">공지</option>
                <option value="이벤트">이벤트</option>
              </select>
              
              <label className="block text-xl font-bold text-gray-700 mb-2">날짜</label>
              <input type="text" value={item.date} onChange={e => handleChange(item.id, 'date', e.target.value)} className="w-full p-3 border border-gray-300 rounded-lg" />
              
              <label className="block text-xl font-bold text-gray-700 mb-2">내용</label>
              <textarea value={item.content} onChange={e => handleChange(item.id, 'content', e.target.value)} className="w-full h-40 p-3 border border-gray-300 rounded-lg"></textarea>
              
              <button onClick={() => handleSave(item.id)} disabled={!editedNotices[item.id]} className="mt-3 px-4 py-2 font-semibold rounded-lg shadow-md transition bg-blue-600 text-white hover:bg-blue-700 cursor-pointer">
                변경사항 저장
              </button>
              <button onClick={() => handleDelete(item.id)} className="mt-3 ml-3 px-4 py-2 font-semibold rounded-lg shadow-md transition bg-red-600 text-white hover:bg-red-700 cursor-pointer">
                삭제
              </button>
            </div>
          )}
        </div>
      ))}

      <h2 className="text-2xl font-bold text-gray-800 mt-10 mb-4">새 게시글 추가</h2>
      <input type="text" placeholder="제목" value={newNotice.title} onChange={e => handleNewNoticeChange('title', e.target.value)} className="w-full p-3 border border-gray-300 rounded-lg mb-2" />
      <input type="text" placeholder="부제목" value={newNotice.subTitle} onChange={e => handleNewNoticeChange('subTitle', e.target.value)} className="w-full p-3 border border-gray-300 rounded-lg mb-2" />
      <input type="text" placeholder="날짜" value={newNotice.date} onChange={e => handleNewNoticeChange('date', e.target.value)} className="w-full p-3 border border-gray-300 rounded-lg mb-2" />
      <select value={newNotice.type} onChange={e => handleNewNoticeChange('type', e.target.value)} className="w-full p-3 border border-gray-300 rounded-lg mb-2">
        <option value="공지">공지</option>
        <option value="이벤트">이벤트</option>
      </select>
      <textarea placeholder="내용" value={newNotice.content} onChange={e => handleNewNoticeChange('content', e.target.value)} className="w-full h-80 p-3 border border-gray-300 rounded-lg mb-4"></textarea>
      <button onClick={handleAddNewNotice} className="px-4 py-2 font-semibold rounded-lg shadow-md bg-green-600 text-white hover:bg-green-700">
        새 게시글 추가
      </button>
    </div>
  );
};

export default NoticeEventAdmin;