import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig.js';
import { useNavigate } from 'react-router-dom';

const Users = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "users"));
        const usersData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(usersData);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4 text-gray-700">유저 리스트</h2>
      <div className="space-y-4">
        {users.map((user) => (
          <div key={user.id} className="bg-white p-4 rounded-lg shadow-md border flex items-center justify-evenly hover:bg-green-100 cursor-pointer"
            onClick={() => navigate('/userdetail', { state: { user } })}>
            <img src={user.picture} alt={`${user.name}'s profile`} className="w-24 h-24 rounded-full mr-8" />
            <div className="flex-1">
              <p><strong>UID:</strong> {user.id}</p>
              <p><strong>Name:</strong> {user.name || "No Name"}</p>
              <p><strong>Email:</strong> {user.email || "no data"}</p>
              <p><strong>Platform:</strong> {user.platform || "no data"}</p>
              <p><strong>Premium:</strong> {user.premium || "no data"}</p>
              <p><strong>Last Login:</strong> {user.last_login ? user.last_login.toDate().toLocaleString() : "no data"}</p>
              <p><strong>Created At:</strong> {user.created_at ? user.created_at.toDate().toLocaleString() : "no data"}</p>
            </div>
            <div className="flex-1">
              <p><strong>Gender:</strong> {user.gender || "no data"}</p>
              <p><strong>Child Name:</strong> {user.InitchildName || "no data"}</p>
              <p><strong>Child Birthday:</strong> {user.InitchildBirthday || "no data"}</p>
              <p><strong>Child Height:</strong> {user.InitchildHeight ? `${user.InitchildHeight} cm` : "no data"}</p>
              <p><strong>Child Weight:</strong> {user.InitchildWeight ? `${user.InitchildWeight} kg` : "no data"}</p>
              <p>
                <strong>Admin:</strong>{" "}
                <span
                  className={user.admin ? "text-red-500 font-bold" : "text-gray-500"}
                >
                  {user.admin ? "Yes" : "No"}
                </span>
              </p>
              <p>
                <strong>Special:</strong>{" "}
                <span
                  className={user.special ? "text-blue-500 font-bold" : "text-gray-500"}
                >
                  {user.special ? "Yes" : "No"}
                </span>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Users;