import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig.js';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import LoadingSpinner from '../components/LoadingSpinner.jsx';

const termsList = [
  { id: 'termsAgreed', label: '서비스 이용 약관' },
  { id: 'privacyAgreed', label: '개인 정보 수집 및 이용 동의' },
  { id: 'policyAgreed', label: '개인 정보 취급 방침 동의' },
  { id: 'sensitiveInfoAgreed', label: '민감 정보 처리 동의' },
  { id: 'policyMypage', label: '개인 정보 수집 및 이용 동의(마이페이지)' },
];

const Terms = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [terms, setTerms] = useState({});
  const [originalTerms, setOriginalTerms] = useState({});
  const [editedTerms, setEditedTerms] = useState({}); // 변경된 필드 추적

  // Firestore에서 데이터 불러오기
  useEffect(() => {
    const fetchTerms = async () => {
      setIsLoading(true);
      try {
        const termsData = {};
        for (let term of termsList) {
          const docRef = doc(db, 'terms', term.id);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            termsData[term.id] = docSnap.data().context;
          }
        }
        setTerms(termsData);
        setOriginalTerms(termsData); // 원본 데이터 저장
      } catch (error) {
        console.error('약관 데이터를 불러오는 중 오류 발생:', error);
      }
      setIsLoading(false);
    };
    fetchTerms();
  }, []);

  // 입력 값 변경 시 editedTerms에 저장
  const handleChange = (id, value) => {
    setTerms({ ...terms, [id]: value });
    setEditedTerms({
      ...editedTerms,
      [id]: originalTerms[id] !== value, // 원본과 비교해서 변경 여부 확인
    });
  };

  // Firestore에 저장
  const handleSave = async (id) => {
    try {
      setIsLoading(true);
      await setDoc(doc(db, 'terms', id), { context: terms[id] });
      setOriginalTerms({ ...originalTerms, [id]: terms[id] }); // 저장 후 원본값 갱신
      setEditedTerms({ ...editedTerms, [id]: false }); // 저장 후 버튼 비활성화
      alert('저장되었습니다!');
    } catch (error) {
      console.error('저장 중 오류 발생:', error);
    }
    setIsLoading(false);
  };

  return (
    <div className="max-w-6xl mx-auto bg-white shadow-md rounded-lg p-6">
      {isLoading && <LoadingSpinner />}
      <h2 className="text-3xl font-bold text-gray-800 mb-1">약관 관리</h2>
      <h2 className="text-sm font-bold text-red-500 mb-6">
        **텍스트** 와 같은 방식으로 감싼 부분은 두꺼운 글꼴 처리 됨
      </h2>

      {termsList.map((item) => (
        <div key={item.id} className="mb-6">
          <label className="block text-xl font-bold text-gray-700 mb-2">
            {item.label}
          </label>
          <textarea
            className="w-full h-80 p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            value={terms[item.id] || ''}
            onChange={(e) => handleChange(item.id, e.target.value)}
          />
          <button
            className={`mt-3 px-4 py-2 font-semibold rounded-lg shadow-md transition ${
              editedTerms[item.id]
                ? 'bg-blue-600 text-white hover:bg-blue-700'
                : 'bg-gray-400 text-gray-200 cursor-not-allowed'
            }`}
            onClick={() => handleSave(item.id)}
            disabled={!editedTerms[item.id]}
          >
            변경사항 저장
          </button>
        </div>
      ))}
    </div>
  );
};

export default Terms;