import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { doc, setDoc, deleteDoc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebaseConfig.js';
import LoadingSpinner from '../components/LoadingSpinner.jsx';

const ExerciseDetail = () => {
  const location = useLocation();
  const { group, subCollection, data: initialData } = location.state || {};

  const [data, setData] = useState(initialData || []);
  const [openDocs, setOpenDocs] = useState(data.map(() => false)); // 각 문서의 토글 상태 관리
  const [newDocId, setNewDocId] = useState(''); // 새 문서 ID 관리
  const [isLoading, setIsLoading] = useState(false);
  const newItemRef = useRef(null);

  if (!data) {
    return <p>해당 컬렉션에 데이터가 없습니다.</p>;
  }

  const handleAddNewDoc = () => {
    if (newDocId.trim() === '') return;

    const newDoc = {
      id: newDocId,
      exercise_data_list: [
        {
          exercise_title: '',
          exercise_description: '',
          exercise_details: [],
          exercise_keypoint: { keypoint_text: [], keypoint_media_url: '' },
          exercise_list: '',
        },
      ],
    };

    setData((prevData) => [...prevData, newDoc]);
    setOpenDocs((prevOpenDocs) => [...prevOpenDocs, true]);
    setNewDocId('');
  };

  const handleToggleDoc = (docIndex) => {
    setOpenDocs(prev => prev.map((isOpen, index) => (index === docIndex ? !isOpen : isOpen)));
  };

  const handleSaveDoc = async (docIndex) => {
    if (window.confirm("이 문서를 저장하시겠습니까?")) {
      const { id, ...docDataWithoutId } = data[docIndex]; // id 필드를 제외한 데이터 추출
      const docRef = doc(db, `exercise/${group}/${subCollection}`, id); // Firestore 경로 설정
  
      setIsLoading(true)
      try {
        await setDoc(docRef, docDataWithoutId); // Firestore에 데이터 덮어쓰기 저장
        console.log("Document saved successfully:", docDataWithoutId);
        alert(`${group} ${subCollection}살 ${id}개월에 해당 데이터 저장을 완료했습니다.`)
      } catch (error) {
        alert('서버에 저장 중 오류가 발생했습니다.')
        console.error("Error saving document:", error);
      }
      setIsLoading(false)
    }
  };

  const handleSaveBackup = async (docIndex) => {
    if (window.confirm("백업 DB에 이 문서를 저장하시겠습니까?")) {
      const { id, ...docDataWithoutId } = data[docIndex]; // id 필드를 제외한 데이터 추출
      const docRef = doc(db, `backup_exercise/${group}/${subCollection}`, id);
      
      setIsLoading(true)
      try {
        await setDoc(docRef, docDataWithoutId); // Firestore에 데이터 덮어쓰기 저장
        console.log("Document backup successfully:", docDataWithoutId);
        alert('백업 서버에 저장했습니다.')
      } catch (error) {
        alert('서버에 저장 중 오류가 발생했습니다.')
        console.error("Error saving document:", error);
      }
      setIsLoading(false)
    }
  };

  const handleDeleteDoc = async (docIndex) => {
    if (window.confirm("정말로 이 문서를 삭제하시겠습니까?")) {
      const docId = data[docIndex].id; // 문서 ID 추출
      const docRef = doc(db, `exercise/${group}/${subCollection}`, docId);
  
      setIsLoading(true)
      try {
        await deleteDoc(docRef); // Firestore에서 문서 삭제
        console.log("Document deleted successfully:", docId);
        alert(`${group} ${subCollection}살 ${docId}개월에 해당 데이터 삭제를 완료했습니다.`)
  
        // 삭제 후, 로컬 상태에서도 해당 문서 제거
        setData(prevData => prevData.filter((_, index) => index !== docIndex));
      } catch (error) {
        console.error("Error deleting document:", error);
        alert('삭제 중 오류가 발생했습니다.')
      }
      setIsLoading(false)
    }
  };
  
  const handleLoadBackupDoc = async (docIndex) => {
    const docId = data[docIndex].id; // 문서 ID 추출
    const backupDocRef = doc(db, `backup_exercise/${group}/${subCollection}`, docId);
  
    setIsLoading(true)
    try {
      const backupDocSnap = await getDoc(backupDocRef);
      if (backupDocSnap.exists()) {
        const backupData = backupDocSnap.data();
        console.log("Backup document loaded:", backupData);
        alert('백업 서버의 데이터를 불러왔습니다.')
  
        // 로컬 상태 업데이트하여 백업 데이터를 input 창에 표시
        setData(prevData =>
          prevData.map((doc, index) => (index === docIndex ? { ...doc, ...backupData } : doc))
        );
      } else {
        alert('백업 서버에 데이터가 존재하지 않습니다.')
        console.log("No backup document found.");
      }
    } catch (error) {
      alert('데이터를 불러오는 중 오류가 발생했습니다.')
      console.error("Error loading backup document:", error);
    }
    setIsLoading(false)
  };
  

  const handleNestedInputChange = (docIndex, itemIndex, field, value) => {
    setData(prevData =>
      prevData.map((doc, index) =>
        index === docIndex
          ? {
              ...doc,
              exercise_data_list: doc.exercise_data_list.map((item, idx) =>
                idx === itemIndex ? { ...item, [field]: value } : item
              ),
            }
          : doc
      )
    );
  };

  const addExerciseItem = (docIndex) => {
    setData(prevData =>
      prevData.map((doc, index) =>
        index === docIndex
          ? {
              ...doc,
              exercise_data_list: [
                ...(doc.exercise_data_list || []),
                {
                  exercise_title: '',
                  exercise_description: '',
                  exercise_details: [],
                  exercise_keypoint: { keypoint_text: [], keypoint_media_url: '' },
                  exercise_list: '',
                },
              ],
            }
          : doc
      )
    );

    // 문서를 열고 새 아이템 위치로 스크롤
    setOpenDocs(prev => prev.map((isOpen, index) => (index === docIndex ? true : isOpen)));
    setTimeout(() => {
      if (newItemRef.current) {
        newItemRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 0);
  };

  const removeExerciseItem = (docIndex, itemIndex) => {
    setData(prevData =>
      prevData.map((doc, index) =>
        index === docIndex
          ? {
              ...doc,
              exercise_data_list: doc.exercise_data_list.filter((_, idx) => idx !== itemIndex),
            }
          : doc
      )
    );
  };

  const addExerciseDetail = (docIndex, itemIndex) => {
    setData(prevData =>
      prevData.map((doc, index) =>
        index === docIndex
          ? {
              ...doc,
              exercise_data_list: doc.exercise_data_list.map((item, idx) =>
                idx === itemIndex
                  ? {
                      ...item,
                      exercise_details: [
                        ...(item.exercise_details || []),
                        { description: '', media_url: '' },
                      ],
                    }
                  : item
              ),
            }
          : doc
      )
    );
  };

  const removeExerciseDetail = (docIndex, itemIndex, detailIndex) => {
    setData(prevData =>
      prevData.map((doc, index) =>
        index === docIndex
          ? {
              ...doc,
              exercise_data_list: doc.exercise_data_list.map((item, idx) =>
                idx === itemIndex
                  ? {
                      ...item,
                      exercise_details: item.exercise_details.filter((_, idx) => idx !== detailIndex),
                    }
                  : item
              ),
            }
          : doc
      )
    );
  };

  const uploadMediaForDetail = async (docIndex, itemIndex, detailIndex) => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';

    fileInput.onchange = async (e) => {
      const file = e.target.files[0];
      if (!file) return;

      const fileRef = ref(storage, `media/exercise/${file.name}`);
      setIsLoading(true)
      try {
        await uploadBytes(fileRef, file);
        const url = await getDownloadURL(fileRef);

        setData((prevData) =>
          prevData.map((doc, dIdx) =>
            dIdx === docIndex
              ? {
                  ...doc,
                  exercise_data_list: doc.exercise_data_list.map((item, iIdx) =>
                    iIdx === itemIndex
                      ? {
                          ...item,
                          exercise_details: item.exercise_details.map((detail, dDetailIdx) =>
                            dDetailIdx === detailIndex
                              ? {
                                  ...detail,
                                  media_url: url,
                                }
                              : detail
                          ),
                        }
                      : item
                  ),
                }
              : doc
          )
        );
        alert('이미지 업로드가 완료됐습니다.')
        console.log("File uploaded successfully:", url);
      } catch (error) {
        alert('이미지 업로드 중 오류가 발생했습니다.')
        console.error("Error uploading file:", error);
      }
      setIsLoading(false)
    };
    fileInput.click();
  };

  return (
    <div className="p-6">
      {isLoading && ( <LoadingSpinner /> )}

      <h1 className="text-2xl font-bold mb-4">운동 상세 정보 / {group} - {subCollection}세</h1>

      <div className="mb-4 flex items-center">
        <input
          type="text"
          placeholder="문서 ID(개월수) 입력"
          value={newDocId}
          onChange={(e) => setNewDocId(e.target.value)}
          className="p-2 border rounded mr-2"
        />
        <button
          onClick={handleAddNewDoc}
          className="px-4 py-2 bg-green-500 text-white rounded shadow hover:bg-green-600 transition-colors"
        >
          문서 추가
        </button>
      </div>
      
      {data.map((doc, docIndex) => (
        <div key={doc.id} className="mb-6 p-4 border rounded-lg shadow-lg bg-white relative">
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center">
              <h3 className="text-lg font-bold">{doc.id}</h3>
              <button
                onClick={() => addExerciseItem(docIndex)}
                className="ml-4 px-4 py-2 bg-green-500 text-white rounded shadow hover:bg-green-600 transition-colors"
              >
                운동 추가
              </button>
              <button
                onClick={() => handleSaveDoc(docIndex)}
                className="ml-4 px-4 py-2 bg-blue-500 text-white rounded shadow hover:bg-blue-600 transition-colors"
              >
                문서 저장
              </button>
              <button
                onClick={() => handleSaveBackup(docIndex)}
                className="ml-4 px-4 py-2 bg-gray-500 text-white rounded shadow hover:bg-gray-600 transition-colors"
              >
                백업 DB에 저장
              </button>
              <button
                onClick={() => handleLoadBackupDoc(docIndex)}
                className="ml-4 px-4 py-2 bg-gray-500 text-white rounded shadow hover:bg-gray-600 transition-colors"
              >
                백업 DB 불러오기
              </button>
              <button
                onClick={() => handleDeleteDoc(docIndex)}
                className="ml-4 px-4 py-2 bg-red-500 text-white rounded shadow hover:bg-red-600 transition-colors"
              >
                문서 삭제
              </button>
            </div>
          </div>

          <div className="flex justify-center mb-2">
            <button
              onClick={() => handleToggleDoc(docIndex)}
              className="text-gray-500 hover:text-gray-700 transition-colors text-lg"
              aria-label="Toggle Document"
            >
              {openDocs[docIndex] ? '▲' : '▼'}
            </button>
          </div>

          {openDocs[docIndex] && doc.exercise_data_list?.map((item, itemIndex) => (
            <div
              key={itemIndex}
              ref={itemIndex === doc.exercise_data_list.length - 1 ? newItemRef : null}
              className="mb-4 p-4 border-l-4 border-blue-500 rounded-lg bg-gray-50 shadow relative"
            >
              <button
                onClick={() => removeExerciseItem(docIndex, itemIndex)}
                className="absolute top-0 right-0 px-4 py-2 bg-red-500 text-white rounded shadow hover:bg-red-600 transition-colors"
              >
                운동 삭제
              </button>
              <h4 className="text-xl font-semibold mb-2 text-blue-600">Exercise Item {itemIndex + 1}</h4>

              <label className="block text-gray-700 font-semibold mb-1">exercise_title</label>
              <input
                type="text"
                value={item.exercise_title}
                onChange={(e) =>
                  handleNestedInputChange(docIndex, itemIndex, "exercise_title", e.target.value)
                }
                className="w-full p-2 border rounded mb-4"
              />

              <label className="block text-gray-700 font-semibold mb-1">exercise_description</label>
              <input
                type="text"
                value={item.exercise_description}
                onChange={(e) =>
                  handleNestedInputChange(docIndex, itemIndex, "exercise_description", e.target.value)
                }
                className="w-full p-2 border rounded mb-4"
              />

              <label className="block text-gray-700 font-semibold mb-1">exercise_details</label>
              {item.exercise_details?.map((detail, detailIndex) => (
                <div key={detailIndex} className="ml-4 p-4 border-l-2 border-gray-300 rounded bg-white mb-4 relative">
                  <button
                    onClick={() => removeExerciseDetail(docIndex, itemIndex, detailIndex)}
                    className="absolute top-0 right-0 mt-2 mr-2 text-red-500 font-bold hover:text-red-700 transition-colors"
                  >
                    X
                  </button>
                  <label className="block text-gray-600 font-semibold mb-1">description</label>
                  <input
                    type="text"
                    value={detail.description}
                    onChange={(e) => {
                      const updatedDetails = [...item.exercise_details];
                      updatedDetails[detailIndex].description = e.target.value;
                      handleNestedInputChange(docIndex, itemIndex, "exercise_details", updatedDetails);
                    }}
                    className="w-full p-2 border rounded mb-2"
                  />

                  <label className="block text-gray-600 font-semibold mb-1">media_url</label>
                  <div className="flex items-center">
                    <input
                      type="text"
                      value={detail.media_url}
                      onChange={(e) => {
                        const updatedDetails = [...item.exercise_details];
                        updatedDetails[detailIndex].media_url = e.target.value;
                        handleNestedInputChange(docIndex, itemIndex, "exercise_details", updatedDetails);
                      }}
                      className="w-full p-2 border rounded mb-2"
                    />
                    <button
                      onClick={() => uploadMediaForDetail(docIndex, itemIndex, detailIndex)}
                      className="ml-2 px-4 py-1 bg-blue-500 text-sm text-white rounded shadow hover:bg-blue-600 transition-colors"
                    >
                      Image Upload
                    </button>
                  </div>
                </div>
              ))}

              <button
                onClick={() => addExerciseDetail(docIndex, itemIndex)}
                className="text-sm px-4 py-2 bg-blue-500 text-white rounded shadow hover:bg-blue-600 transition-colors"
              >
                Exercise Detail 정보 추가
              </button>

              <label className="block text-gray-700 font-semibold mt-4 mb-1">exercise_keypoint</label>
              <div className="ml-4 p-4 border-l-2 border-gray-300 rounded bg-white mb-4">
                <label className="block text-gray-600 font-semibold mb-1">keypoint_text</label>
                {item.exercise_keypoint?.keypoint_text.map((text, textIndex) => (
                  <div key={textIndex} className="flex items-center mb-2">
                    <input
                      type="text"
                      value={text}
                      onChange={(e) => {
                        const updatedKeypoint = {
                          ...item.exercise_keypoint,
                          keypoint_text: item.exercise_keypoint.keypoint_text.map((t, idx) =>
                            idx === textIndex ? e.target.value : t
                          )
                        };
                        handleNestedInputChange(docIndex, itemIndex, "exercise_keypoint", updatedKeypoint);
                      }}
                      className="w-full p-2 border rounded"
                    />
                    <button
                      onClick={() => {
                        const updatedKeypoint = {
                          ...item.exercise_keypoint,
                          keypoint_text: item.exercise_keypoint.keypoint_text.filter((_, idx) => idx !== textIndex)
                        };
                        handleNestedInputChange(docIndex, itemIndex, "exercise_keypoint", updatedKeypoint);
                      }}
                      className="ml-2 px-2 py-1 text-red-500 font-bold hover:text-red-700 transition-colors"
                    >
                      X
                    </button>
                  </div>
                ))}
                <button
                  onClick={() => {
                    const updatedKeypoint = {
                      ...item.exercise_keypoint,
                      keypoint_text: [...(item.exercise_keypoint.keypoint_text || []), ""]
                    };
                    handleNestedInputChange(docIndex, itemIndex, "exercise_keypoint", updatedKeypoint);
                  }}
                  className="px-4 py-2 bg-blue-500 text-sm text-white rounded shadow hover:bg-blue-600 transition-colors"
                >
                  키포인트 텍스트 추가
                </button>


                <label className="block text-gray-600 font-semibold mb-1">keypoint_media_url</label>
                <input
                  type="text"
                  value={item.exercise_keypoint?.keypoint_media_url}
                  onChange={(e) => {
                    const updatedKeypoint = { ...item.exercise_keypoint, keypoint_media_url: e.target.value };
                    handleNestedInputChange(docIndex, itemIndex, "exercise_keypoint", updatedKeypoint);
                  }}
                  className="w-full p-2 border rounded mb-4"
                />
              </div>

              <label className="block text-gray-700 font-semibold mb-1">exercise_list</label>
              <input
                type="text"
                value={item.exercise_list}
                onChange={(e) =>
                  handleNestedInputChange(docIndex, itemIndex, "exercise_list", e.target.value)
                }
                className="w-full p-2 border rounded mb-4"
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ExerciseDetail;