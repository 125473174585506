import React from 'react';
import { useNavigate } from 'react-router-dom';

const docs = ['common', 'free', 'premium'];
const subCollections = [
  'under_3', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15_above',
];

const colors = {
  common: 'bg-blue-500 hover:bg-blue-600',
  free: 'bg-green-500 hover:bg-green-600',
  premium: 'bg-[#DAA520] hover:bg-[#C89E40]',
};

const Survey = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-4 text-gray-700">설문 카테고리</h1>

      {docs.map((doc) => (
        <div key={doc} className="mb-6">
          <h2 className="text-xl font-semibold mb-2 text-gray-600 capitalize">{doc}</h2>
          <div className="grid grid-cols-8 gap-4">
            {(doc === 'premium' ? [...subCollections, '999'] : subCollections).map((subCollection) => (
              <button
                key={`${doc}-${subCollection}`}
                className={`px-2 py-1 text-white rounded-lg shadow transition-colors ${colors[doc]}`}
                onClick={() => navigate('/surveydetail', { state: { doc, subCollection } })}
              >
                {subCollection}
              </button>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Survey;