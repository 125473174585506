import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db, functions, httpsCallable } from '../firebaseConfig.js';
import LoadingSpinner from '../components/LoadingSpinner.jsx';

const UserComment = () => {
  const location = useLocation();
  const { user } = location.state || {};
  const [userComments, setUserComments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchUserComments = async () => {
      if (!user?.id) {
        console.error("User ID is missing");
        return;
      }

      setIsLoading(true);
      try {
        const commentDocRef = doc(db, `community_logs/${user.id}/comment/commentIds`);
        const commentDoc = await getDoc(commentDocRef);

        if (commentDoc.exists()) {
          const commentIdArray = commentDoc.data().commentId;
          const fetchedComments = [];

          for (const { commentId, postId } of commentIdArray) {
            const commentRef = doc(db, `community/${postId}/comments/${commentId}`);
            const commentDoc = await getDoc(commentRef);

            if (commentDoc.exists()) {
              const commentData = commentDoc.data()[commentId];

              if (commentData) {
                fetchedComments.push({
                  id: commentId,
                  postId,
                  details: commentData.details,
                  uid: commentData.uid,
                  updatedAt: commentData.updatedAt,
                });
              }
            }
          }

          fetchedComments.sort((a, b) => (b.updatedAt || '').localeCompare(a.updatedAt || ''));
          setUserComments(fetchedComments);
        } else {
          console.error("No comment document found for the user");
        }
      } catch (error) {
        console.error("Error fetching user comments:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserComments();
  }, [user]);

  const handleDeleteComment = async (commentId, postId, uid) => {
    const confirmDelete = window.confirm("정말로 이 댓글을 삭제하시겠습니까?");
    if (!confirmDelete) return;

    setIsLoading(true);
    try {
      const deleteComment = httpsCallable(functions, 'deleteCommentData');
      await deleteComment({ postId, commentId, uid });
      
      setUserComments((prevComments) =>
        prevComments.filter((comment) => comment.id !== commentId)
      );
      alert("댓글이 성공적으로 삭제되었습니다.");
    } catch (error) {
      alert("댓글 삭제 중 오류가 발생했습니다.");
      console.error(`Error deleting comment with ID: ${commentId} for post ID: ${postId}`, error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen relative">
      {isLoading && ( <LoadingSpinner /> )}

      <h1 className="text-2xl font-bold mb-6 text-gray-700">
        {user.name}의 댓글 ({userComments.length})
      </h1>
      <div className="space-y-4">
        {userComments.length > 0 ? (
          userComments.map((comment) => (
            <div key={comment.id} className="p-4 bg-white border rounded-lg shadow-md relative">
              <div className="flex justify-between items-center">
                <div className="flex-1">
                  <p className="text-sm text-gray-500 mb-1">User ID: {comment.uid}</p>
                  <p className="text-sm text-gray-500 mb-1">Updated At: {comment.updatedAt || 'N/A'}</p>
                </div>
                <div className="flex-1">
                  <p className="text-sm text-gray-500 mb-1">Comment ID: {comment.id}</p>
                  <p className="text-sm text-gray-500 mb-1">Post ID: {comment.postId}</p>
                </div>
                <button
                  onClick={() => handleDeleteComment(comment.id, comment.postId, comment.uid)}
                  className="px-4 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors duration-200"
                >
                  삭제
                </button>
              </div>
              <div className="flex border-t border-gray-300 mt-4 pt-4">
                <p className="text-gray-700 mb-4">{comment.details}</p>
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-700">No comments found for this user.</p>
        )}
      </div>
    </div>
  );
};

export default UserComment;