import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { doc, getDoc, updateDoc, Timestamp } from 'firebase/firestore';
import { db } from '../firebaseConfig.js';
import LoadingSpinner from '../components/LoadingSpinner.jsx';

const UserPremium = () => {
  const location = useLocation();
  const { user } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);
  const [itemData, setItemData] = useState({});
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!user?.id) return;
      setIsLoading(true);
      try {
        const itemDocRef = doc(db, `users/${user.id}/checkout/item`);
        const itemSnapshot = await getDoc(itemDocRef);

        if (itemSnapshot.exists()) {
          const data = itemSnapshot.data();
          setItemData({
            ...data,
            count: data.count || 0, // 숫자 데이터 처리
            ticket: data.ticket || 0, // 숫자 데이터 처리
            annualplan: data.annualplan || false, // 기본값 처리
            premium_due_date: data.premium_due_date
              ? new Date(data.premium_due_date.toDate().getTime() + 9 * 60 * 60 * 1000) // UTC+9 변환
                  .toISOString()
                  .substring(0, 10)
              : '', // 빈 값 처리
          });
        } else {
          setItemData({ count: 0, ticket: 0, annualplan: false, premium_due_date: '' }); // 기본값 설정
          console.warn('No data found at the specified path.');
        }
      } catch (error) {
        console.error('Error fetching item data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const parsedValue = name === 'annualplan' ? value === 'yes' : value;

    setItemData((prevData) => ({
      ...prevData,
      [name]: name === 'count' || name === 'ticket' ? parseInt(value) || 0 : parsedValue,
    }));
    setIsModified(true);
  };

  const handleSave = async () => {
    if (!user?.id) return;
  
    // 만료 기간 검증
    const dueDate = new Date(itemData.premium_due_date);
    const currentDate = new Date();
  
    if (itemData.ticket > 0 && !itemData.premium_due_date) {
      alert('premium_due_date가 설정되지 않았습니다. 티켓을 설정할 경우 만료 기간을 설정해주세요.');
      return;
    }
  
    if (itemData.ticket > 0 && dueDate < currentDate) {
      alert('티켓이 있는데 만료 기간이 끝났습니다. 연장을 원하면 premium_due_date를 설정해주세요.');
      return;
    }
  
    setIsLoading(true);
    try {
      const itemDocRef = doc(db, `users/${user.id}/checkout/item`);
  
      const updatedData = {
        ...itemData,
        premium_due_date: itemData.premium_due_date
          ? Timestamp.fromDate(
              new Date(new Date(itemData.premium_due_date).getTime() - 9 * 60 * 60 * 1000) // UTC+9로 저장
            )
          : null, // 빈 값 처리
      };
  
      await updateDoc(itemDocRef, updatedData);
      alert('저장 성공\n관리자가 임의로 티켓이나 premium_due_date를 조작할 경우 Premium 상태는 자동으로 갱신되지 않습니다. Premium 상태 갱신이 필요하면 UserDetail 페이지에서 수동으로 변경해주세요.');
      setIsModified(false);
    } catch (error) {
      console.error('Error updating item data:', error);
      alert('서버에 저장 중 오류가 발생했습니다.');
    } finally {
      setIsLoading(false);
    }
  };  

  return (
    <div className="p-6 bg-gray-100 min-h-screen relative">
      {isLoading && <LoadingSpinner />}

      <h1 className="text-2xl font-bold mb-6 text-gray-700">
        {user?.name || '유저'}의 티켓 및 프리미엄 정보
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {Object.keys(itemData)
          .sort() // 필드 이름을 알파벳순으로 정렬
          .map((key) => (
            <div key={key} className="mb-4">
              <label className="block text-gray-700 font-bold mb-2">{key}:</label>
              {key === 'count' || key === 'ticket' ? (
                <input
                  type="number"
                  name={key}
                  value={itemData[key]}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded-md"
                  placeholder={`Enter ${key}`}
                />
              ) : key === 'premium_due_date' ? (
                <input
                  type="date"
                  name={key}
                  value={itemData[key]}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded-md"
                />
              ) : key === 'annualplan' ? (
                <select
                  name={key}
                  value={itemData[key] ? 'yes' : 'no'}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded-md"
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              ) : (
                <input
                  type="text"
                  name={key}
                  value={itemData[key] || ''}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded-md"
                  placeholder={`Enter ${key}`}
                />
              )}
            </div>
          ))}
      </div>

      <button
        onClick={handleSave}
        disabled={!isModified || isLoading}
        className={`p-2 mt-4 rounded-md ${
          isModified && !isLoading
            ? 'bg-green-500 text-white hover:bg-green-600'
            : 'bg-gray-400 text-gray-200 cursor-not-allowed'
        }`}
      >
        {isLoading ? '저장중...' : '저장하기'}
      </button>
    </div>
  );
};

export default UserPremium;
