import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig.js';
import LoadingSpinner from '../components/LoadingSpinner.jsx';

const UserReport = () => {
  const location = useLocation();
  const { user } = location.state || {};
  const [surveyResult, setSurveyResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchSurveyResult = async () => {
      if (!user || !user.id) {
        console.log("No user or uid found.");
        return;
      }

      setIsLoading(true)
      try {
        const surveyDocRef = doc(db, `users/${user.id}/report`, 'survey-result');
        const docSnapshot = await getDoc(surveyDocRef);

        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setSurveyResult(data);
        } else {
          console.log("No survey result found.");
        }
      } catch (error) {
        console.error("Error fetching survey result:", error);
      }
      setIsLoading(false)
    };

    fetchSurveyResult();
  }, [user]);

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      { isLoading && ( <LoadingSpinner /> )}
      <div>
        {surveyResult ? (
          Object.entries(surveyResult)
            .sort((a, b) => (a[0] < b[0] ? 1 : -1))
            .map(([surveyId, details]) => (
              <div key={surveyId} className="mb-6 p-6 bg-white border rounded-lg shadow-md">
                <h3 className="text-lg font-semibold mb-4 text-gray-800">{surveyId}</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-4">
                  {Object.entries(details)
                    .sort(([keyA], [keyB]) => (keyA < keyB ? -1 : 1))
                    .map(([key, value]) => (
                      <div key={key} className="flex items-center">
                        <span className="font-semibold text-gray-600 w-1/3">{key}:</span>
                        <span className="text-gray-800 w-2/3">
                          {key === 'common1' ? (value ? 'Yes' : 'No') : value}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            ))
        ) : (
          <p className="text-gray-600">No survey results found.</p>
        )}
      </div>
    </div>
  );
};

export default UserReport;