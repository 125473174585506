import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig.js';
import { UserContext } from '../Contexts/UserContext.jsx';
import LoadingSpinner from '../components/LoadingSpinner.jsx';

const Counsel = () => {
  const [counselHistory, setCounselHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // 로딩 상태 추가
  const navigate = useNavigate();
  const { allUsers, isUserLoading } = useContext(UserContext);

  useEffect(() => {
    const fetchAllCounselHistory = async () => {
      if (isUserLoading) return; // User data가 로딩 중이면 기다리기

      const startYear = 2024;
      const currentYear = new Date().getUTCFullYear();
      const years = Array.from({ length: currentYear - startYear + 1 }, (_, i) => (startYear + i).toString());
      const history = [];

      try {
        for (const userId of allUsers.map(user => user.uid)) {
          for (const year of years) {
            const subCollectionRef = collection(db, `counselHistory/${userId}/${year}`);
            const subDocsSnapshot = await getDocs(subCollectionRef);

            subDocsSnapshot.forEach((doc) => {
              const docData = doc.data();
              const sortedFields = Object.keys(docData).sort();

              const oldestField = sortedFields[0];
              const latestField = sortedFields[sortedFields.length - 1];

              history.push({
                userId,
                year,
                docId: doc.id,
                allDetails: docData,
                dateOldest: `${year}.${doc.id.slice(0, 2)}.${doc.id.slice(2, 4)} (Oldest)`,
                detailsOldest: docData[oldestField],
                dateLatest: `${year}.${doc.id.slice(0, 2)}.${doc.id.slice(2, 4)} (Latest)`,
                detailsLatest: docData[latestField],
              });
            });
          }
        }

        history.sort((a, b) => (a.dateLatest < b.dateLatest ? 1 : -1));
        setCounselHistory(history);
      } catch (error) {
        console.error("Error fetching counsel history:", error);
      } finally {
        setIsLoading(false); // 로딩이 끝난 후 로딩 상태 false로 설정
      }
    };

    fetchAllCounselHistory();
  }, [isUserLoading, allUsers]);

  return (
    <div className="p-6 bg-gray-100 min-h-screen relative">
      {isLoading && ( <LoadingSpinner /> )}

      <h1 className="text-2xl font-bold mb-6 text-gray-700">전체 상담 기록</h1>
      <div className="space-y-4">
        {counselHistory.map((entry, index) => {
          const { userId, detailsOldest, detailsLatest } = entry;
          const user = allUsers.find((u) => u.uid === userId);
          const userName = user ? user.name : "Unknown";
          const userPicture = user ? user.picture : "";

          return (
            <div
              key={index}
              onClick={() => navigate('/usercounseldetail', { state: { allDetails: entry.allDetails, year: entry.year, docId: entry.docId, userId } })}
              className="p-4 bg-white border rounded-lg shadow-md hover:bg-gray-200 cursor-pointer transition-colors"
            >
              <div className="flex items-center mb-4">
                <img src={userPicture} alt="User Profile" className="w-10 h-10 rounded-full mr-4" />
                <h3 className="text-lg font-semibold text-gray-800 mb-2">
                  User ID: {userId} - {userName}
                </h3>
              </div>
              <div className="flex justify-between">
                <div className="w-1/2 pr-4">
                  <h3 className="text-lg font-semibold text-gray-800 mb-2">{entry.dateOldest}</h3>
                  <p className="text-gray-700 mb-2"><strong>Context:</strong> {detailsOldest.details.context}</p>
                  <p className={`text-sm font-semibold ${detailsOldest.user === userId ? "text-green-500" : "text-red-500"}`}>
                    {detailsOldest.user === userId ? "User" : "Admin"}
                  </p>
                </div>
                <div className="w-1/2 pl-4 border-l border-gray-200">
                  <h3 className="text-lg font-semibold text-gray-800 mb-2">{entry.dateLatest}</h3>
                  <p className="text-gray-700 mb-2"><strong>Context:</strong> {detailsLatest.details.context}</p>
                  <p className={`text-sm font-semibold ${detailsLatest.user === userId ? "text-green-500" : "text-red-500"}`}>
                    {detailsLatest.user === userId ? "User" : "Admin"}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Counsel;