import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const BackupExerciseDetail = () => {
  const location = useLocation();
  const { group, subCollection, data: initialData } = location.state || {};

  const [data, setData] = useState(initialData || []);
  const [openDocs, setOpenDocs] = useState(data.map(() => false)); // 각 문서의 토글 상태 관리
  const newItemRef = useRef(null);

  if (!data) {
    return <p>해당 컬렉션에 데이터가 없습니다.</p>;
  }

  const handleToggleDoc = (docIndex) => {
    setOpenDocs(prev => prev.map((isOpen, index) => (index === docIndex ? !isOpen : isOpen)));
  };

  const handleNestedInputChange = (docIndex, itemIndex, field, value) => {
    setData(prevData =>
      prevData.map((doc, index) =>
        index === docIndex
          ? {
              ...doc,
              exercise_data_list: doc.exercise_data_list.map((item, idx) =>
                idx === itemIndex ? { ...item, [field]: value } : item
              ),
            }
          : doc
      )
    );
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">[백업] 운동 상세 정보 / {group} - {subCollection}세</h1>
      
      {data.map((doc, docIndex) => (
        <div key={doc.id} className="mb-6 p-4 border rounded-lg shadow-lg bg-white relative">
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center">
              <h3 className="text-lg font-bold">{doc.id}</h3>
            </div>
          </div>

          <div className="flex justify-center mb-2">
            <button
              onClick={() => handleToggleDoc(docIndex)}
              className="text-gray-500 hover:text-gray-700 transition-colors text-lg"
              aria-label="Toggle Document"
            >
              {openDocs[docIndex] ? '▲' : '▼'}
            </button>
          </div>

          {openDocs[docIndex] && doc.exercise_data_list?.map((item, itemIndex) => (
            <div
              key={itemIndex}
              ref={itemIndex === doc.exercise_data_list.length - 1 ? newItemRef : null}
              className="mb-4 p-4 border-l-4 border-blue-500 rounded-lg bg-gray-50 shadow relative"
            >
              <h4 className="text-xl font-semibold mb-2 text-blue-600">Exercise Item {itemIndex + 1}</h4>

              <label className="block text-gray-700 font-semibold mb-1">exercise_title</label>
              <input
                type="text"
                value={item.exercise_title}
                onChange={(e) =>
                  handleNestedInputChange(docIndex, itemIndex, "exercise_title", e.target.value)
                }
                className="w-full p-2 border rounded mb-4"
              />

              <label className="block text-gray-700 font-semibold mb-1">exercise_description</label>
              <input
                type="text"
                value={item.exercise_description}
                onChange={(e) =>
                  handleNestedInputChange(docIndex, itemIndex, "exercise_description", e.target.value)
                }
                className="w-full p-2 border rounded mb-4"
              />

              <label className="block text-gray-700 font-semibold mb-1">exercise_details</label>
              {item.exercise_details?.map((detail, detailIndex) => (
                <div key={detailIndex} className="ml-4 p-4 border-l-2 border-gray-300 rounded bg-white mb-4 relative">
                  <label className="block text-gray-600 font-semibold mb-1">description</label>
                  <input
                    type="text"
                    value={detail.description}
                    onChange={(e) => {
                      const updatedDetails = [...item.exercise_details];
                      updatedDetails[detailIndex].description = e.target.value;
                      handleNestedInputChange(docIndex, itemIndex, "exercise_details", updatedDetails);
                    }}
                    className="w-full p-2 border rounded mb-2"
                  />

                  <label className="block text-gray-600 font-semibold mb-1">media_url</label>
                  <input
                    type="text"
                    value={detail.media_url}
                    onChange={(e) => {
                      const updatedDetails = [...item.exercise_details];
                      updatedDetails[detailIndex].media_url = e.target.value;
                      handleNestedInputChange(docIndex, itemIndex, "exercise_details", updatedDetails);
                    }}
                    className="w-full p-2 border rounded mb-2"
                  />
                </div>
              ))}

              <label className="block text-gray-700 font-semibold mt-4 mb-1">exercise_keypoint</label>
              <div className="ml-4 p-4 border-l-2 border-gray-300 rounded bg-white mb-4">
                <label className="block text-gray-600 font-semibold mb-1">keypoint_text</label>
                {item.exercise_keypoint?.keypoint_text.map((text, textIndex) => (
                  <div key={textIndex} className="flex items-center mb-2">
                    <input
                      type="text"
                      value={text}
                      onChange={(e) => {
                        const updatedKeypoint = {
                          ...item.exercise_keypoint,
                          keypoint_text: item.exercise_keypoint.keypoint_text.map((t, idx) =>
                            idx === textIndex ? e.target.value : t
                          )
                        };
                        handleNestedInputChange(docIndex, itemIndex, "exercise_keypoint", updatedKeypoint);
                      }}
                      className="w-full p-2 border rounded"
                    />
                  </div>
                ))}

                <label className="block text-gray-600 font-semibold mb-1">keypoint_media_url</label>
                <input
                  type="text"
                  value={item.exercise_keypoint?.keypoint_media_url}
                  onChange={(e) => {
                    const updatedKeypoint = { ...item.exercise_keypoint, keypoint_media_url: e.target.value };
                    handleNestedInputChange(docIndex, itemIndex, "exercise_keypoint", updatedKeypoint);
                  }}
                  className="w-full p-2 border rounded mb-4"
                />
              </div>

              <label className="block text-gray-700 font-semibold mb-1">exercise_list</label>
              <input
                type="text"
                value={item.exercise_list}
                onChange={(e) =>
                  handleNestedInputChange(docIndex, itemIndex, "exercise_list", e.target.value)
                }
                className="w-full p-2 border rounded mb-4"
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default BackupExerciseDetail;