import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { collection, getDocs, doc, updateDoc, setDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig.js';
import LoadingSpinner from '../components/LoadingSpinner.jsx';

const UserAnalyze = () => {
  const location = useLocation();
  const { user } = location.state || {};
  const [diagData, setDiagData] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [balanceData, setBalanceData] = useState({});
  const [peakPressureData, setPeakPressureData] = useState({});
  const [pressureData, setPressureData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const defaultBalanceData = {
    balance: { value: '', type: 'string', name: '균형' },
    direction: { value: '', type: 'string', name: '방향' },
    rate: { value: 0, type: 'number', name: '비율' },
    left: { value: 0, type: 'number', name: '왼쪽' },
    right: { value: 0, type: 'number', name: '오른쪽' },
  };

  const defaultPeakPressureData = {
    copiIndex: { left: { value: '', type: 'string' }, right: { value: '', type: 'string' }, name: '발아치(COPI)' },
    footArea: { left: { value: 0, type: 'number' }, right: { value: 0, type: 'number' }, name: '발면적(cm2)' },
    peakPressure: { left: { value: 0, type: 'number' }, right: { value: 0, type: 'number' }, name: '최고 압력 값(Kpa)' },
    peakPressurePosition: { left: { value: '', type: 'string' }, right: { value: '', type: 'string' }, name: '최고 압력 위치(Kpa)' },
  };

  const defaultPressureData = {
    areaCount: { left: { value: 0, type: 'number' }, right: { value: 0, type: 'number' }, name: '면적수 (총 10구획)' },
    averagePressure: { left: { value: 0, type: 'number' }, right: { value: 0, type: 'number' }, name: '구획당 평균 압력(Kpa)' },
    totalPressure: { left: { value: 0, type: 'number' }, right: { value: 0, type: 'number' }, name: '총 압력 값(Kpa)' },
  };

  const parseValue = (value, type) => {
    return type === 'number' ? parseFloat(value) || 0 : value;
  };

  useEffect(() => {
    if (user) fetchDiagData();
    // eslint-disable-next-line
  }, [user]);

  const fetchDiagData = async () => {
    setIsLoading(true);
    try {
      const diagCollectionRef = collection(db, `users/${user.id}/diagDate`);
      const snapshot = await getDocs(diagCollectionRef);
      const data = {};
      snapshot.docs.forEach((doc) => {
        data[doc.id] = doc.data();
      });
      setDiagData(data);

    } catch (error) {
      console.error("Error fetching diag data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const selectDate = (date) => {
    const dateData = diagData[date] || {};
    setSelectedDate({
      date,
      time: dateData.time || '18.30',
    });
  
    setBalanceData(dateData.balanceData || Object.fromEntries(Object.entries(defaultBalanceData).map(([key, { value }]) => [key, value])));
    setPeakPressureData(dateData.peakPressureData || Object.fromEntries(Object.entries(defaultPeakPressureData).map(([key, subFields]) => [
      key, Object.fromEntries(Object.entries(subFields).map(([subKey, { value }]) => [subKey, value]))
    ])));
    setPressureData(dateData.pressureData || Object.fromEntries(Object.entries(defaultPressureData).map(([key, subFields]) => [
      key, Object.fromEntries(Object.entries(subFields).map(([subKey, { value }]) => [subKey, value]))
    ])));
  };
  

  const handleBalanceDataChange = (field, value) => {
    setBalanceData((prev) => ({ ...prev, [field]: parseValue(value, defaultBalanceData[field].type) }));
  };

  const handlePeakPressureDataChange = (subCategory, field, value) => {
    setPeakPressureData((prev) => ({
      ...prev,
      [subCategory]: {
        ...prev[subCategory],
        [field]: parseValue(value, defaultPeakPressureData[subCategory][field].type),
      },
    }));
  };

  const handlePressureDataChange = (subCategory, field, value) => {
    setPressureData((prev) => ({
      ...prev,
      [subCategory]: {
        ...prev[subCategory],
        [field]: parseValue(value, defaultPressureData[subCategory][field].type),
      },
    }));
  };

  const saveDiagData = async () => {
    if (!selectedDate) return;

    setIsSaving(true);
    try {
      const docRef = doc(db, `users/${user.id}/diagDate`, selectedDate.date);
      await updateDoc(docRef, {
        balanceData,
        peakPressureData,
        pressureData,
        time: selectedDate.time
      });
      alert("데이터가 저장되었습니다.");
      await fetchDiagData()
    } catch (error) {
      console.error("Error saving diag data:", error);
      alert("서버 통신 중 오류가 발생했습니다.");
    } finally {
      setIsSaving(false);
    }
  };

  const removeNameFields = (obj) => {
    if (typeof obj !== 'object' || obj === null) return obj;
  
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [
        key,
        typeof value === 'object' && !Array.isArray(value) ? removeNameFields(value) : value,
      ]).filter(([key, value]) => key !== 'name') // name 필드 제거
    );
  };
  
  const addNewDocument = async () => {
    const year = prompt("연도를 입력하세요 (예: 2024):");
    if (!year || isNaN(year) || year.length !== 4) {
      alert("올바른 연도를 입력하세요.");
      return;
    }
  
    const month = prompt("월을 입력하세요 (1~12):");
    if (!month || isNaN(month) || month < 1 || month > 12) {
      alert("올바른 월을 입력하세요.");
      return;
    }
  
    const day = prompt("일을 입력하세요 (1~31):");
    if (!day || isNaN(day) || day < 1 || day > 31) {
      alert("올바른 일을 입력하세요.");
      return;
    }
  
    // YYYY-MM-DD 형식으로 조합
    const newDate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
  
    setIsSaving(true);
    try {
      const docRef = doc(db, `users/${user.id}/diagDate`, newDate);
  
      // name 필드 제거 후 저장
      await setDoc(docRef, {
        balanceData: removeNameFields(
          Object.fromEntries(Object.entries(defaultBalanceData).map(([key, { value }]) => [key, value]))
        ),
        peakPressureData: removeNameFields(
          Object.fromEntries(Object.entries(defaultPeakPressureData).map(([key, subFields]) => [
            key,
            Object.fromEntries(Object.entries(subFields).map(([subKey, { value }]) => [subKey, value])),
          ]))
        ),
        pressureData: removeNameFields(
          Object.fromEntries(Object.entries(defaultPressureData).map(([key, subFields]) => [
            key,
            Object.fromEntries(Object.entries(subFields).map(([subKey, { value }]) => [subKey, value])),
          ]))
        ),
        time: '18.30',
      });
  
      alert("새 문서가 추가되었습니다.");
      fetchDiagData();
    } catch (error) {
      console.error("Error adding new document:", error);
      alert("서버 통신 중 오류가 발생했습니다.");
    } finally {
      setIsSaving(false);
    }
  };  

  const deleteDocument = async () => {
    if (!selectedDate) return;

    const confirmDelete = window.confirm(`${selectedDate.date}의 데이터를 삭제하시겠습니까?`);
    if (!confirmDelete) return;

    setIsSaving(true);
    try {
      const docRef = doc(db, `users/${user.id}/diagDate`, selectedDate.date);
      await deleteDoc(docRef);
      alert("문서가 삭제되었습니다.");
      fetchDiagData();
      setSelectedDate(null);
    } catch (error) {
      console.error("Error deleting document:", error);
      alert("서버 통신 중 오류가 발생했습니다.");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-md">
      {isLoading && <LoadingSpinner />}
      <h1 className="text-2xl font-bold text-gray-700 mb-4">
        진단 데이터 - {user.name} ( {user.id} )
      </h1>

      <div className="mb-4">
        <h3 className="text-lg font-semibold text-gray-600">날짜 선택:</h3>
        <div className="flex flex-wrap space-x-2">
          {Object.keys(diagData).map((date) => (
            <button
              key={date}
              onClick={() => selectDate(date)}
              className={`px-4 py-2 rounded-md ${
                selectedDate?.date === date ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'
              }`}
            >
              {date}
            </button>
          ))}
        </div>
      </div>

      <div className="flex space-x-4 mb-4">
        <button
          onClick={addNewDocument}
          className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors duration-200"
        >
          새 문서 추가
        </button>
        <button
          onClick={deleteDocument}
          disabled={!selectedDate}
          className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors duration-200 disabled:bg-gray-300"
        >
          문서 삭제
        </button>
      </div>

      {selectedDate ? (
        <>
          {/* Time Input Field */}
          <div className="p-4 bg-white border rounded-lg shadow-md mb-4">
            <h3 className="text-lg font-semibold text-gray-600 mb-2">Time</h3>
            <input
              type="text"
              value={selectedDate?.time || '18.30'} // 선택된 문서의 time 값
              onChange={(e) =>
                setSelectedDate((prev) => ({ ...prev, time: e.target.value }))
              }
              className="w-40 p-2 border rounded"
              placeholder="Enter time (e.g., 18:30)"
            />
          </div>
          <div className="p-4 bg-white border rounded-lg shadow-md mb-4">
            <h3 className="text-lg font-semibold text-gray-600 mb-2">balanceData "신체 균형 분석"</h3>
            {Object.entries(balanceData).sort().map(([field, value]) => (
              <div key={field} className="flex items-center space-x-2 mb-2">
                <label className="w-[300px] font-medium text-gray-700">{field} "{defaultBalanceData[field].name}":</label>
                <input
                  type={defaultBalanceData[field].type === "number" ? "number" : "text"}
                  value={value}
                  onChange={(e) => handleBalanceDataChange(field, e.target.value)}
                  className="w-full p-2 border rounded"
                />
              </div>
            ))}
          </div>

          <div className="p-4 bg-white border rounded-lg shadow-md mb-4">
            <h3 className="text-lg font-semibold text-gray-600 mb-2">peakPressureData "발 최고 압력"</h3>
            {Object.entries(peakPressureData).sort().map(([subCategory, fields]) => (
              <div key={subCategory} className="mb-2">
                <h4 className="font-semibold text-gray-700 mb-1">{subCategory} "{defaultPeakPressureData[subCategory].name}"</h4>
                {Object.entries(fields).sort().map(([field, value]) => (
                  <div key={field} className="flex items-center space-x-2 mb-2">
                    <label className="w-[300px] font-medium text-gray-700">{field}:</label>
                    <input
                      type={defaultPeakPressureData[subCategory][field].type === "number" ? "number" : "text"}
                      value={value}
                      onChange={(e) =>
                        handlePeakPressureDataChange(subCategory, field, e.target.value)
                      }
                      className="w-full p-2 border rounded"
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div className="p-4 bg-white border rounded-lg shadow-md mb-4">
            <h3 className="text-lg font-semibold text-gray-600 mb-2">pressureData "발 압력 분포 분석"</h3>
            {Object.entries(pressureData).sort().map(([subCategory, fields]) => (
              <div key={subCategory} className="mb-2">
                <h4 className="font-semibold text-gray-700 mb-1">{subCategory} "{defaultPressureData[subCategory].name}"</h4>
                {Object.entries(fields).sort().map(([field, value]) => (
                  <div key={field} className="flex items-center space-x-2 mb-2">
                    <label className="w-[300px] font-medium text-gray-700">{field}:</label>
                    <input
                      type={defaultPressureData[subCategory][field].type === "number" ? "number" : "text"}
                      value={value}
                      onChange={(e) =>
                        handlePressureDataChange(subCategory, field, e.target.value)
                      }
                      className="w-full p-2 border rounded"
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>

          <button
            onClick={saveDiagData}
            disabled={isSaving}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-200 disabled:bg-gray-300"
          >
            {isSaving ? '저장 중...' : '저장'}
          </button>
        </>
      ) : (
        <p className="text-gray-700">날짜를 선택해 주세요.</p>
      )}
    </div>
  );
};

export default UserAnalyze;