import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig.js';
import LoadingSpinner from '../components/LoadingSpinner.jsx';

const UserExerciseRate = () => {
  const location = useLocation();
  const { user } = location.state || {};
  const [exerciseData, setExerciseData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [expandedYear, setExpandedYear] = useState(null);
  const [expandedMonth, setExpandedMonth] = useState({});
  const [expandedDay, setExpandedDay] = useState({});

  useEffect(() => {
    const fetchExerciseData = async () => {
      if (!user?.id) {
        console.error("User ID is missing");
        return;
      }

      setIsLoading(true);
      const fetchedData = {};

      try {
        const currentYear = new Date().getFullYear();

        for (let year = 2024; year <= currentYear; year++) {
          const yearRef = collection(db, `exercise_rate/${user.id}/${year}`);
          const yearSnapshot = await getDocs(yearRef);

          const monthlyData = {};
          yearSnapshot.forEach((doc) => {
            const docData = doc.data();
            const month = doc.id.slice(0, 2); // MM 형식의 월 추출
            monthlyData[month] = docData;
          });

          fetchedData[year] = monthlyData;
        }

        setExerciseData(fetchedData);
      } catch (error) {
        console.error("Error fetching exercise rate data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchExerciseData();
  }, [user]);

  const toggleYear = (year) => {
    setExpandedYear((prevYear) => (prevYear === year ? null : year));
  };

  const toggleMonth = (year, month) => {
    setExpandedMonth((prevMonths) => ({
      ...prevMonths,
      [year]: prevMonths[year] === month ? null : month,
    }));
  };

  const toggleDay = (year, month, day) => {
    setExpandedDay((prevDays) => ({
      ...prevDays,
      [`${year}-${month}`]: prevDays[`${year}-${month}`] === day ? null : day,
    }));
  };

  const renderData = (data) => {
    if (typeof data === 'object' && data !== null) {
      return (
        <div className="ml-4 space-y-2">
          {Array.isArray(data) ? (
            <ul className="list-disc ml-4 space-y-1">
              {data.map((item, index) => (
                <li key={index}>{renderData(item)}</li>
              ))}
            </ul>
          ) : (
            Object.entries(data)
              .sort(([a], [b]) => Number(b) - Number(a)) // 필드값 내림차순 정렬
              .map(([key, value]) => (
                <div key={key} className="text-gray-700">
                  <span className="font-semibold">{key}: </span>
                  {key === 'completed' && typeof value === 'boolean' ? (
                    <span>{value ? 'Yes' : 'No'}</span>
                  ) : typeof value === 'object' ? (
                    renderData(value)
                  ) : (
                    <span>{value}</span>
                  )}
                </div>
              ))
          )}
        </div>
      );
    } else {
      return <span>{data}</span>;
    }
  };

  return (
    <div className="p-6 bg-gray-100 min-h-[90vh] relative">
      {isLoading && <LoadingSpinner />}

      <h1 className="text-2xl font-bold mb-6 text-gray-700">
        {user.name}의 운동 기록
      </h1>

      <div className="space-y-4">
        {Object.keys(exerciseData).length > 0 ? (
          Object.entries(exerciseData).map(([year, months]) => (
            <div key={year} className="p-4 bg-white border rounded-lg shadow-md">
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-semibold text-gray-800">
                  {year}년 운동 기록
                </h2>
                <button
                  onClick={() => toggleYear(year)}
                  className="px-4 py-2 bg-blue-400 text-white rounded-md hover:bg-blue-500 transition-colors duration-200"
                >
                  {expandedYear === year ? '닫기' : '열기'}
                </button>
              </div>

              {expandedYear === year && (
                <div className="mt-4 space-y-2">
                  {Object.keys(months)
                    .sort((a, b) => Number(b) - Number(a))
                    .map((month) => (
                      <div key={month} className="p-3 bg-gray-100 rounded-lg">
                        <div className="flex justify-between items-center">
                          <h3 className="text-lg font-semibold text-gray-700">
                            {month}월 운동 기록
                          </h3>
                          <button
                            onClick={() => toggleMonth(year, month)}
                            className="px-4 py-1 bg-green-400 text-white rounded-md hover:bg-green-500 transition-colors duration-200"
                          >
                            {expandedMonth[year] === month ? '닫기' : '열기'}
                          </button>
                        </div>

                        {expandedMonth[year] === month && (
                          <div className="mt-2 flex flex-wrap gap-2">
                            {Object.keys(months[month])
                              .sort((a, b) => Number(a) - Number(b))
                              .map((day) => (
                                <button
                                  key={day}
                                  onClick={() => toggleDay(year, month, day)}
                                  className="px-3 py-1 bg-yellow-400 text-white rounded-md hover:bg-yellow-500 transition-colors duration-200"
                                >
                                  {day}일
                                </button>
                              ))}
                          </div>
                        )}

                        {expandedMonth[year] === month && expandedDay[`${year}-${month}`] && (
                          <div className="mt-4 p-4 bg-gray-200 rounded-lg">
                            <h4 className="text-md font-semibold text-gray-700">
                              {expandedDay[`${year}-${month}`]}일 기록
                            </h4>
                            {renderData(months[month][expandedDay[`${year}-${month}`]])}
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              )}
            </div>
          ))
        ) : (
          <p className="text-gray-700">운동 기록이 없습니다.</p>
        )}
      </div>
    </div>
  );
};

export default UserExerciseRate;