import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig.js';
import LoadingSpinner from '../components/LoadingSpinner.jsx';

const UserDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = location.state;

  const initialFormData = {
    name: user.name || '',
    email: user.email || '',
    platform: user.platform || 'free',
    premium: user.premium || 'free',
    gender: user.gender || '',
    InitchildName: user.InitchildName || '',
    InitchildBirthday: user.InitchildBirthday || '',
    InitchildHeight: user.InitchildHeight || '',
    InitchildWeight: user.InitchildWeight || '',
    phone: user.phone || '',
    special: user.special || false,
    admin: user.admin || false,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isModified, setIsModified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const isFormModified = JSON.stringify(formData) !== JSON.stringify(initialFormData);
    setIsModified(isFormModified);
    // eslint-disable-next-line
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: name === 'special' || name === 'admin' ? value === 'true' : value, // Boolean으로 변환
    }));
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const userRef = doc(db, "users", user.id);
      await updateDoc(userRef, formData);
      alert("유저 정보 변경 사항을 저장했습니다.");
      navigate(-1);
    } catch (error) {
      alert("서버 통신중 에러가 발생했습니다.");
      console.error("Error updating user information:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // 버튼 정보 배열
  const buttons = [
    { label: '프리미엄', path: '/userpremium' },
    { label: '설문결과', path: '/userreport' },
    { label: '상담', path: '/usercounsel' },
    { label: '운동 달성 정보', path: '/userexerciserate' },
    { label: '유저 발 분석 정보', path: '/useranalyze' },
    { label: '게시글', path: '/userpost' },
    { label: '댓글', path: '/usercomment' },
  ];

  return (
    <div className="relative bg-gray-100 p-6 rounded-lg shadow-md">
      <div className="flex gap-10">
        <h2 className="text-2xl font-bold mb-4 text-gray-700">유저 상세 정보</h2>
        <div className="flex justify-start space-x-4 mb-6">
          {buttons.map((button) => (
            <button
              key={button.label}
              onClick={() => navigate(button.path, { state: { user } })}
              className="px-4 py-2 bg-blue-400 text-white rounded-md hover:bg-blue-500"
            >
              {button.label}
            </button>
          ))}
        </div>
      </div>

      {/* 유저 수정 폼 */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        {Object.keys(formData).map((key) => (
          <div key={key} className="mb-2 relative">
            <label className="block text-gray-700 text-sm font-bold mb-2">{key}:</label>
            {key === 'premium' ? (
              <select
                name="premium"
                value={formData.premium}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
              >
                <option value="free">Free</option>
                <option value="premium">Premium</option>
              </select>
            ) : key === 'gender' ? (
              <select
                name="gender"
                value={formData.gender}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            ) : key === 'special' || key === 'admin' ? (
              <select
                name={key}
                value={formData[key].toString()} // Boolean 값을 문자열로 변환하여 select에 표시
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
              >
                <option value="true">해당</option>
                <option value="false">미해당</option>
              </select>
            ) : key === 'platform' ? (
              <input
                type="text"
                name={key}
                value={formData[key]}
                readOnly
                className="w-full p-2 border rounded-md bg-gray-100 cursor-not-allowed"
              />
            ) : (
              <input
                type="text"
                name={key}
                value={formData[key]}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
              />
            )}
          </div>
        ))}
      </div>

      <button
        onClick={handleSave}
        disabled={!isModified || isLoading}
        className={`p-2 rounded-md mt-4 ${
          isModified && !isLoading
            ? 'bg-green-500 text-white hover:bg-green-600 cursor-pointer'
            : 'bg-gray-400 text-gray-200 cursor-not-allowed'
        }`}
      >
        {isLoading ? 'Saving...' : 'Save Changes'}
      </button>

      {isLoading && <LoadingSpinner />}
    </div>
  );
};

export default UserDetail;