import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig.js';

const UserCounsel = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = location.state || {};
  const [counselHistory, setCounselHistory] = useState([]);

  useEffect(() => {
    const fetchCounselHistory = async () => {
      if (!user || !user.id) {
        console.log("No user or uid found.");
        return;
      }

      const startYear = 2024;
      const currentYear = new Date().getUTCFullYear();
      const years = Array.from({ length: currentYear - startYear + 1 }, (_, i) => (startYear + i).toString());

      const history = [];

      try {
        for (const year of years) {
          const subCollectionRef = collection(db, "counselHistory", user.id, year);
          const subDocsSnapshot = await getDocs(subCollectionRef);

          subDocsSnapshot.forEach((doc) => {
            const docData = doc.data();
            const sortedFields = Object.keys(docData).sort(); // 필드명을 날짜순으로 정렬

            const oldestField = sortedFields[0];
            const latestField = sortedFields[sortedFields.length - 1];

            history.push({
              year,
              docId: doc.id,
              allDetails: docData, // 문서 전체 데이터
              dateOldest: `${year}.${doc.id.slice(0, 2)}.${doc.id.slice(2, 4)} (Oldest)`,
              detailsOldest: docData[oldestField],
              userIdOldest: docData[oldestField].user,
              dateLatest: `${year}.${doc.id.slice(0, 2)}.${doc.id.slice(2, 4)} (Latest)`,
              detailsLatest: docData[latestField],
              userIdLatest: docData[latestField].user,
            });
          });
        }

        // 최신순 정렬
        history.sort((a, b) => (a.dateLatest < b.dateLatest ? 1 : -1));
        setCounselHistory(history);

      } catch (error) {
        console.error("Error fetching counsel history:", error);
      }
    };

    fetchCounselHistory();
  }, [user]);

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-bold mb-6 text-gray-700">{user.name}의 상담 기록</h1>
      <div className="space-y-4">
        {counselHistory.map((entry, index) => (
          <div
            key={index}
            onClick={() => navigate('/usercounseldetail', { state: { allDetails: entry.allDetails, year: entry.year, docId: entry.docId, userId: user.id } })}
            className="p-4 bg-white border rounded-lg shadow-md flex justify-between cursor-pointer hover:bg-gray-200 transition-colors"
          >
            {/* Left side: Oldest data */}
            <div className="w-1/2 pr-4">
              <h3 className="text-lg font-semibold text-gray-800 mb-2">{entry.dateOldest}</h3>
              <p className="text-gray-700 mb-2">
                <strong>User ID:</strong> {entry.userIdOldest}
                <span className={entry.userIdOldest === user.id ? "text-green-500" : "text-red-500"}> 
                  {entry.userIdOldest === user.id ? " (User)" : " (Admin)"}
                </span>
              </p>
              <p className="text-gray-700 mb-2"><strong>Context:</strong> {entry.detailsOldest.details.context}</p>
            </div>
            {/* Right side: Latest data */}
            <div className="w-1/2 pl-4 border-l border-gray-200">
              <h3 className="text-lg font-semibold text-gray-800 mb-2">{entry.dateLatest}</h3>
              <p className="text-gray-700 mb-2">
                <strong>User ID:</strong> {entry.userIdLatest}
                <span className={entry.userIdLatest === user.id ? "text-green-500" : "text-red-500"}>
                  {entry.userIdLatest === user.id ? " (User)" : " (Admin)"}
                </span>
              </p>
              <p className="text-gray-700 mb-2"><strong>Context:</strong> {entry.detailsLatest.details.context}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserCounsel;