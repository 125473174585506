import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBopQJoHQy0x4MKzqz-y8Itbtn_Ig6EoPY",
  authDomain: "bebetherafit-mobile.firebaseapp.com",
  projectId: "bebetherafit-mobile",
  storageBucket: "bebetherafit-mobile.appspot.com",
  messagingSenderId: "268559185810",
  appId: "1:268559185810:web:348c1c0f23f870b3bab34b"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
const functions = getFunctions(app);

export { db, storage, auth, functions, httpsCallable };
